import { useApolloClient } from "@apollo/client";
import { Flex, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";

import { BACKEND_URL } from "@/constants";

import AuthenticationContext from "./authentication-context";
import initialValues from "./initial-values";
import { loadWhoami } from "./load-whoami";

export function AuthenticationContextProvider({ children }: { children: React.ReactNode }) {
  const apolloClient = useApolloClient();

  const [isBooting, setIsBooting] = useState(true);
  const [state, setState] = useState(initialValues);

  useEffect(() => {
    (async function main() {
      const response = await loadWhoami();

      // no active session known
      if (response === undefined || !response.user.groups.includes("GROUP_EMPLOYEES")) {
        setIsBooting(false);
        return;
      }

      // user is logged in.
      setState(current => ({ ...current, isAuthenticated: true, user: { id: response.user.id, email: response.user.email } }));
      setIsBooting(false);
    })();
  }, []);

  const handleOnLogin = async (credential: string) => {
    setState(state => ({
      ...state,
      isFetching: true,
      isAuthenticated: false,
      user: { id: 0, email: undefined },
    }));

    try {
      const response = await fetch(BACKEND_URL + "/users/exchange", {
        method: "POST",
        headers: { "Content-Type": "text/json" },
        body: JSON.stringify({ credential }),
        credentials: "include",
      });

      const content_ = (await response.json()) as LoginSuccessResponse | LoginErrorResponse;
      if (!response.ok) throw new Error((content_ as LoginErrorResponse).message);

      const content = content_ as LoginSuccessResponse;
      if (!content.user) throw new Error("Woops, something went wrong");
      localStorage.setItem("hasActiveSession", "1");

      setState(state => ({
        ...state,
        isAuthenticated: true,
        isFetching: false,
        user: { id: content.user.id, email: content.user.email },
      }));
    } catch (error) {
      setState(state => ({
        ...state,
        isAuthenticated: false,
        isFetching: false,
        user: { id: 0, email: undefined },
      }));

      throw error;
    }
  };

  const handleOnLogout = async () => {
    try {
      await fetch(BACKEND_URL + "/users/logout", { credentials: "include" });
    } catch (_error) {
      // ..
    }

    localStorage.removeItem("userId");
    apolloClient.resetStore();

    setState(state => ({
      ...state,
      isAuthenticated: false,
      isFetching: false,
      user: {
        id: 0,
        email: initialValues.user.email,
      },
    }));
  };

  const authContextValue = useMemo(
    () => ({
      ...state,
      loginUser: handleOnLogin,
      logoutUser: handleOnLogout,
    }),
    [state]
  );

  return (
    <AuthenticationContext.Provider value={authContextValue}>
      {isBooting ? (
        <Flex align="center" justify="center" style={{ height: "100vh", width: "100vw" }}>
          <Spin />
        </Flex>
      ) : (
        children
      )}
    </AuthenticationContext.Provider>
  );
}

type LoginSuccessResponse = {
  user: {
    id: number;
    email: string;
    locale: string;
  };
};

type LoginErrorResponse = {
  message: string;
};
