import { gql, useQuery } from "@apollo/client";
import { Card, Table } from "antd";

interface DealerBelongingToCardProps {
  onClick: (relationId: string) => void;
  relationId: string;
}

export function DealerBelongingToCard({ onClick, relationId }: DealerBelongingToCardProps) {
  const { data, loading } = useQuery(DealerRelationsQuery, { variables: { dealerId: relationId } });

  return (
    <Card title="Gekoppelde klanten" bodyStyle={{ padding: 1, margin: 0 }}>
      <Table
        columns={COLUMNS}
        className="table-clickable-rows"
        loading={loading}
        dataSource={data?.relations ?? []}
        onRow={record => ({
          onClick: () => onClick(record.id),
        })}
        style={{ borderBottomWidth: 0 }}
      />
    </Card>
  );
}

const COLUMNS = [
  { title: "AFAS", dataIndex: "afasCode" },
  { title: "Relatie", dataIndex: "name" },
  { title: "Vestigingslocatie", dataIndex: "place" },
];

const DealerRelationsQuery = gql`
  query ($dealerId: ID!) {
    relations(dealerId: $dealerId) {
      id
      afasCode
      name
      place
    }
  }
`;
