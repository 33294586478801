import { Space } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AppointmentModal from "@/components/AppointmentModal";
import RegisterIssueModal from "@/components/RegisterIssueModal";
import ShowIssueModal from "@/components/ShowIssueModal";

import { AppointmentRemindMeCard } from "./components/AppointmentRemindMeCard";
import IssueListCard from "./components/IssueListCard";

export default function Dashboard() {
  const navigate = useNavigate();

  const [raiseIssueModalVisible, setRaiseIssueModalVisible] = useState(false);
  const [selectedIssueId, setSelectedIssueId] = useState<string>();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>();

  const handleOnClickAppointment = (appointmentId: string) => {
    setSelectedAppointmentId(appointmentId);
  };

  const handleOnIssueClick = (issueId: string) => {
    setSelectedIssueId(issueId);
  };

  const handleOnShowAllClick = () => {
    navigate("/issues");
  };

  return (
    <>
      {undefined !== selectedIssueId && <ShowIssueModal onClose={() => setSelectedIssueId(undefined)} issueId={selectedIssueId} />}
      {undefined !== selectedAppointmentId && (
        <AppointmentModal onClose={() => setSelectedAppointmentId(undefined)} appointmentId={selectedAppointmentId} />
      )}
      {raiseIssueModalVisible && <RegisterIssueModal onClose={() => setRaiseIssueModalVisible(false)} />}
      <Space direction="vertical">
        <IssueListCard
          onClick={handleOnIssueClick}
          onCreateClick={() => setRaiseIssueModalVisible(true)}
          onShowAllClick={handleOnShowAllClick}
        />

        <AppointmentRemindMeCard onClick={handleOnClickAppointment} />
      </Space>
    </>
  );
}
