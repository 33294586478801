import { Select, SelectProps } from "antd";
import * as React from "react";

export default function LocalePicker(props: SelectProps) {
  return (
    <Select {...props}>
      <Select.Option value="nl_NL">🇳🇱 Nederlands (Nederland)</Select.Option>
      <Select.Option value="nl_BE">🇳🇱 Nederlands (België)</Select.Option>
      <Select.Option value="fr_BE">🇫🇷 Frans (België)</Select.Option>
    </Select>
  );
}
