import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadFile, UploadProps } from "antd";

import { BACKEND_URL } from "@/constants";

interface AttachmentInputProps {
  onChange?: (value: UploadFile[]) => void;
}

export default function AttachmentInput({ disabled, onChange, ...props }: Omit<UploadProps, "locale" | "onChange"> & AttachmentInputProps) {
  const handleChange: UploadProps["onChange"] = event => {
    const value = Array.isArray(event) ? (event as unknown as UploadFile[]) : event?.fileList;

    onChange?.(value);
  };

  return (
    <Upload {...props} {...DEFAULT_UPLOAD_PROPS} disabled={disabled} onChange={handleChange}>
      <Button disabled={disabled} icon={<UploadOutlined />} size="small">
        Bijlage(s) toevoegen
      </Button>
    </Upload>
  );
}

export function filterAttachmentIdentifiers(input: UploadFile[]) {
  return input.map(element => element.response?.id ?? element.uid);
}

function removeFile(fileId: number) {
  return fetch(BACKEND_URL + "/file/" + fileId, { method: "DELETE", credentials: "include" });
}

const DEFAULT_UPLOAD_PROPS: UploadProps = {
  action: BACKEND_URL + "/file",
  withCredentials: true,
  onRemove: async file => {
    const fileId: number | undefined = file.response?.id;
    if (undefined !== fileId) await removeFile(fileId);

    return;
  },
};
