import { WarningFilled } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Alert, Form, Table } from "antd";
import { NamePath } from "antd/lib/form/interface";
import truncate from "lodash/truncate";

import formatAddress from "@/functions/format-address";
import useDebounce from "@/hooks/use-debounce";

interface WarnForExistingSerialCodeProps {
  productTypePath: NamePath;
  serialCodePath: NamePath;
}

export default function WarnForExistingSerialCode({ productTypePath, serialCodePath }: WarnForExistingSerialCodeProps) {
  const form = Form.useFormInstance();
  const productType = Form.useWatch(productTypePath);
  const serialCode = Form.useWatch(serialCodePath);

  const debouncedSerialCode = useDebounce(serialCode);
  const productTypeId =
    typeof productType === "string" ? productType : Array.isArray(productType) ? productType[productType.length - 1] : undefined;

  const { data } = useQuery<UseProductData, UseProductVariables>(ProductsQuery, {
    variables: { productTypeId, serialCode: debouncedSerialCode, offset: 0, limit: 10 },
    skip: !productTypeId || !serialCode,
  });

  if (undefined === data || data?.products?.totalCount < 1 || !form.isFieldTouched("serialCode")) {
    return null;
  }

  return (
    <Alert
      showIcon={false}
      type="warning"
      message={
        <>
          <span>
            <WarningFilled style={{ marginRight: 4 }} />
            Let op! Een product met dit serienummer is al geregistreerd. Toch doorgaan?
          </span>
          <Table
            bordered
            columns={[
              { dataIndex: ["location", "relation"], title: "Relatie", render: relation => `(${relation.afasCode}) ${relation.name}` },
              {
                dataIndex: "location",
                title: "Locatie",
                render: location => (
                  <>
                    <span style={{ display: "block" }}>{location.name}</span>
                    <em style={{ display: "block", marginTop: 4 }}>
                      {truncate(formatAddress(location.address), { length: 75, separator: /,? +/ })}
                    </em>
                  </>
                ),
              },
            ]}
            dataSource={data.products.edges}
            pagination={false}
            size="small"
            style={{ marginTop: 12 }}
          />
        </>
      }
      style={{ marginBlockStart: -10, marginBlockEnd: 14 }}
    />
  );
}

const ProductsQuery = gql`
  query ($productTypeId: ID, $serialCode: String, $offset: Int!, $limit: Int!) {
    products(productTypeId: $productTypeId, serialCode: $serialCode, limit: $limit, offset: $offset) {
      totalCount
      edges {
        id
        location {
          id
          name
          address {
            street
            postalCode
            city
            country
          }
          relation {
            id
            afasCode
            name
          }
        }
      }
    }
  }
`;

interface UseProductVariables {
  productTypeId: string;
  serialCode: string;
  offset: number;
  limit: number;
}

interface UseProductData {
  products: {
    totalCount: number;
    edges: Array<{
      id: string;
      location: {
        id: string;
        name: string;
        relation: {
          id: string;
          afasCode: string;
          name: string;
        };
        address: {
          street: string;
          postalCode: string;
          city: string;
          country: string;
        };
      };
    }>;
  };
}
