import { Space } from "antd";
import * as React from "react";

import RegisterIssueModal from "@/components/RegisterIssueModal";
import ShowIssueModal from "@/components/ShowIssueModal";

import CondensedVisitInfo from "./CondensedVisitInfo";
import ExtendedVisitInfo from "./ExtendedVisitInfo";
import RelatedIssuesTable from "./RelatedIssuesTable";
import WorksheetItemsTable from "./WorksheetItemsTable";

interface WorksheetTableItemProps {
  mode?: "condensed" | "extended";
  worksheetId: string;
}

export default function WorksheetTableItem({ mode = "condensed", worksheetId }: WorksheetTableItemProps) {
  const [selectedIssueId, setSelectedIssueId] = React.useState<string | undefined>();
  const [registerIssueModalProps, setRegisterIssueModalProps] = React.useState<Record<string, unknown> | undefined>(undefined);

  const handleOnCreateIssueClick = (values: Record<string, unknown>) => {
    setRegisterIssueModalProps(values);
  };

  return (
    <>
      <Space direction="vertical" size="middle">
        {mode === "extended" ? (
          <ExtendedVisitInfo onCreateIssueClick={handleOnCreateIssueClick} worksheetId={worksheetId} />
        ) : (
          <CondensedVisitInfo onCreateIssueClick={handleOnCreateIssueClick} worksheetId={worksheetId} />
        )}
        <WorksheetItemsTable onCreateIssueClick={handleOnCreateIssueClick} worksheetId={worksheetId} />
        <RelatedIssuesTable onClick={issueId => setSelectedIssueId(issueId)} worksheetId={worksheetId} />
      </Space>

      {/* eslint-disable-next-line prettier/prettier */}
      {undefined !== selectedIssueId && <ShowIssueModal onClose={() => setSelectedIssueId(undefined)} issueId={selectedIssueId} />}
      {undefined !== registerIssueModalProps && (
        // eslint-disable-next-line prettier/prettier
        <RegisterIssueModal onClose={() => setRegisterIssueModalProps(undefined)} initialValues={registerIssueModalProps} />
      )}
    </>
  );
}
