import { gql, useQuery } from "@apollo/client";
import { Table } from "antd";
import dayjs from "dayjs";
import truncate from "lodash/truncate";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppointmentModal from "@/components/AppointmentModal";
import TableFilterDropdown from "@/components/TableFilterDropdown";
import formatAddress from "@/functions/format-address";
import simpleTableFilterSorter from "@/functions/simple-table-filter-sorter";

import { useDealerRelations } from "../useDealerRelations";

interface PastAndFutureAppointmentsTabPaneProps {
  dealerId: string;
}

function collectLocations(locations: Array<{ id: string; name: string; address: object }>) {
  return locations
    .map(l => ({ text: `${l.name} (${formatAddress(l.address as any)})`, value: l.id })) // ..
    .sort(simpleTableFilterSorter);
}

function collectRelations(relations: Array<{ id: string; name: string; afasCode: string | null }>) {
  return relations
    .map(x => ({ value: x.id, text: x.afasCode !== null ? `(${x.afasCode}) ${x.name}` : x.name }))
    .sort(simpleTableFilterSorter);
}

const STATUS_LIST = [
  { text: "Aangevraagd", value: "STATUS_REQUESTED" },
  { text: "Ingeroosterd", value: "STATUS_SCHEDULED" },
  { text: "Bevestigd", value: "STATUS_CONFIRMED" },
  { text: "Geannuleerd", value: "STATUS_CANCELLED" },
];

const PAGE_SIZE = 10;

const DEFAULT_FILTERS: Record<string, string | string[] | undefined> = {
  relationId: undefined,
  locationId: undefined,
  status: undefined,
};

export function PastAndFutureAppointmentsTabPane({ dealerId }: PastAndFutureAppointmentsTabPaneProps) {
  const navigate = useNavigate();

  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [isAppointmentModalOpenWithId, setIsAppointmentModalOpenWithId] = useState<string>();

  const { relations, locations } = useDealerRelations(dealerId);
  const { data, loading } = useQuery(PastAndFutureAppointmentsQuery, { variables: { ...filters, dealerId, offset } });
  const appointments = data?.pastAndFutureAppointments.edges ?? [];

  const columns = useMemo(
    () => [
      {
        title: "Klant",
        key: "customer",
        children: [
          {
            title: "Relatie",
            dataIndex: ["location", "relation"],
            key: "relation",
            filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectRelations(relations)} />,
            render: relation => {
              return relation.afasCode !== null ? `(${relation.afasCode}) ${relation.name}` : relation.name;
            },
          },
          {
            title: "Locatie",
            dataIndex: ["location"],
            key: "location",
            filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectLocations(locations)} />,
            render: location => (
              <>
                <span style={{ display: "block" }}>{location.name}</span>
                <em style={{ display: "block", marginTop: 4 }}>
                  {truncate(formatAddress(location.address), { length: 75, separator: /,? +/ })}
                </em>
              </>
            ),
          },
        ],
      },
      {
        title: "Datum",
        render: (_, record) => dayjs(record.startTime ?? record.createdOn ?? record.prospectiveDate).format("LL"),
        key: "date",
      },
      {
        title: "Tijd",
        key: "time",
        render: (_, record) => {
          // only worksheet is available
          if ((record.startTime === null || record.endTime === null) && record.createdOn !== null && record.completedOn !== null) {
            const fmtActual = `${dayjs(record.createdOn).format("HH:mm")} - ${dayjs(record.completedOn).format("HH:mm")}`;
            return <span style={{ fontStyle: "italic" }}>${fmtActual}</span>;
          }

          // only appointment is available
          if (record.startTime !== null && record.endTime !== null && (record.createdOn === null || record.completedOn === null)) {
            const fmtPlanned = `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`;

            return fmtPlanned;
          }

          // both are available
          if (record.startTime !== null && record.endTime !== null && record.createdOn !== null && record.completedOn !== null) {
            const fmtActual = `${dayjs(record.createdOn).format("HH:mm")} - ${dayjs(record.completedOn).format("HH:mm")}`;
            const fmtPlanned = `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`;

            return (
              <span>
                {fmtActual + " "}
                <span style={{ fontStyle: "italic" }}>({fmtPlanned})</span>
              </span>
            );
          }

          // neither are available
          return null;
        },
      },
      {
        title: "Soort",
        dataIndex: "appointmentType",
        render: appointmentType => appointmentType?.name ?? "-",
      },
      {
        title: "Status",
        dataIndex: "status",
        filters: STATUS_LIST,
        render: status => STATUS_LIST.find(x => x.value === status)?.text ?? "-",
      },
    ],
    [appointments]
  );

  return (
    <>
      {isAppointmentModalOpenWithId && (
        <AppointmentModal appointmentId={isAppointmentModalOpenWithId} onClose={() => setIsAppointmentModalOpenWithId(undefined)} />
      )}
      <Table
        bordered
        columns={columns}
        dataSource={appointments}
        onChange={(pagination, filters) => {
          setOffset(((pagination.current ?? 1) - 1) * (pagination.pageSize ?? PAGE_SIZE));

          setFilters({
            relationId: (filters["relation"]?.[0] as string) ?? undefined,
            locationId: (filters["location"]?.[0] as string) ?? undefined,
            status: (filters["status"] as string[]) ?? undefined,
          });
        }}
        onRow={useCallback(
          record => ({
            onClick: () => {
              if (record.worksheetId) {
                navigate(`/worksheets/${record.worksheetId}`);
              } else if (record.appointmentId) {
                setIsAppointmentModalOpenWithId(record.appointmentId);
              }
            },
          }),
          []
        )}
        rowKey={element => `${element.appointmentId}x${element.worksheetId}`}
        loading={loading}
        pagination={{ total: data?.pastAndFutureAppointments.totalCount ?? undefined, pageSize: PAGE_SIZE }}
      />
    </>
  );
}

const PastAndFutureAppointmentsQuery = gql`
  query ($dealerId: ID!, $relationId: ID, $locationId: ID, $status: [AppointmentStatus!], $offset: Int!) {
    pastAndFutureAppointments(dealerId: $dealerId, relationId: $relationId, locationId: $locationId, status: $status, offset: $offset) {
      totalCount
      edges {
        appointmentId
        worksheetId
        startTime
        endTime
        createdOn
        completedOn
        prospectiveDate
        location {
          id
          name
          relation {
            id
            afasCode
            name
          }
          address {
            street
            postalCode
            city
            country
            coordinates {
              latitude
              longitude
            }
          }
        }
        appointmentType {
          id
          name
        }
        employee {
          id
          username
        }
        status
      }
    }
  }
`;
