import { gql, useQuery } from "@apollo/client";

export function useDealerRelations(dealerId: string) {
  const { data: relationData } = useQuery<DealerRelationsQueryData>(DealerRelationsQuery, { variables: { dealerId } });
  const relations = relationData?.relations ?? [];
  const locations = relations.flatMap(x => x.locations);

  return { relations, locations };
}

interface DealerRelationsQueryData {
  relations: Array<{
    id: string;
    name: string;
    afasCode: string | null;
    locations: Array<{
      id: string;
      name: string;
      address: {
        street: string | null;
        postalCode: string | null;
        city: string | null;
        country: string | null;
      };
    }>;
  }>;
}

const DealerRelationsQuery = gql`
  query ($dealerId: ID!) {
    relations(dealerId: $dealerId) {
      id
      name
      afasCode
      locations {
        id
        name
        address {
          street
          postalCode
          city
          country
        }
      }
    }
  }
`;
