import { Button, Divider, Space, Switch, Table } from "antd";
import dayjs from "dayjs";

import Gravatar from "@/components/Gravatar";

interface UserTableProps {
  dataSource: Array<{ id: string; username: string; email: string; enabled: boolean }>;
  loading: boolean;
  onClick: (userId: string) => void;
  onCreateClick?: () => void;
  onIsActiveChange: () => void;
  isActive: boolean;
}

export default function UserTable({ dataSource, loading, onClick, onCreateClick, onIsActiveChange, isActive }: UserTableProps) {
  return (
    <Table
      className="table-clickable-rows"
      bordered
      columns={[
        {
          dataIndex: "email",
          title: null,
          render: email => <Gravatar className="ant-avatar ant-avatar-circle" email={email} />,
          width: 50,
        },
        {
          dataIndex: "username",
          title: "Gebruikersnaam",
          sorter: (a, b) => a.username.localeCompare(b.username),
          defaultSortOrder: "ascend",
          sortDirections: ["ascend", "descend"],
          filters: dataSource.map(user => ({ text: user.username, value: user.id })),
          onFilter: (value, record) => record.id === value,
          filterSearch: true,
        },
        {
          dataIndex: "email",
          title: "E-mail",
          sorter: (a, b) => a.email.localeCompare(b.email),
          sortDirections: ["ascend", "descend"],
          filters: dataSource.map(user => ({ text: user.email, value: user.id })),
          onFilter: (value, record) => record.id === value,
          filterSearch: true,
        },
        {
          dataIndex: "lastSeenAt",
          title: "Laatste keer gezien",
          render: lastSeenAt => (lastSeenAt ? dayjs(lastSeenAt).format("LLLL") : "-"),
        },
        {
          dataIndex: "enabled",
          title: "Actief",
          render: enabled => (enabled ? "Ja" : "Nee"),
        },
      ]}
      dataSource={dataSource}
      loading={loading}
      onRow={record => ({
        onClick: () => onClick(record.id),
      })}
      rowKey={record => record.id}
      title={() => (
        <Space direction="horizontal">
          {onCreateClick && (
            <>
              <Button onClick={() => onCreateClick()}>Gebruiker aanmaken</Button>
              <Divider type="vertical" />
            </>
          )}
          <span>Alleen actieve gebruikers</span>
          <Switch checked={isActive} onChange={() => onIsActiveChange()} />
        </Space>
      )}
    />
  );
}
