import { useState } from "react";

import EditProductModal from "@/components/EditProductModal";
import RegisterProductModal from "@/components/RegisterProductModal";

import { ProductsTable } from "./ProductsTable";

interface ProductsTabPaneProps {
  dealerId: string;
}

export function ProductsTabPane({ dealerId }: ProductsTabPaneProps) {
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>(undefined);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);

  return (
    <>
      {registerModalOpen && <RegisterProductModal dealerId={dealerId} onClose={() => setRegisterModalOpen(false)} />}
      {undefined !== selectedProductId && (
        // eslint-disable-next-line prettier/prettier
        <EditProductModal onClose={() => setSelectedProductId(undefined)} productId={selectedProductId} />
      )}
      <ProductsTable
        dealerId={dealerId}
        onCreateClick={() => setRegisterModalOpen(true)}
        onClick={productId => setSelectedProductId(productId)}
      />
    </>
  );
}
