import { Layout, Menu, MenuProps } from "antd";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import routes from "@/routes/routes";

type MenuItem = Required<MenuProps>["items"][number];
function reduceMenuFromRoutes(routes_: typeof routes, path: string[] = []): MenuItem[] {
  return routes_
    .filter(route => route.inMenu)
    .map(route => {
      const pathPrefix = [...path, route.href ?? route.path];

      const children_ = Array.isArray(route.children) ? reduceMenuFromRoutes(route.children, pathPrefix) : undefined;
      const children = undefined !== children_ && children_.length > 0 ? children_ : undefined;

      return {
        children,
        key: route.name,
        icon: route.icon,
        label:
          children === undefined ? (
            <Link to={"/" + [...path, route.href ?? route.path].join("/")} style={{ color: "inherit" }}>
              {route.name}
            </Link>
          ) : (
            route.name
          ),
      };
    });
}

export default function SideMenu() {
  const { pathname } = useLocation();

  const initialSelectedKeys = pathname.substring(1).split("/");
  const items = useMemo(() => reduceMenuFromRoutes(routes), []);

  return (
    <Layout.Sider width={225} style={{ background: "#fff" }}>
      <Menu
        defaultOpenKeys={initialSelectedKeys}
        defaultSelectedKeys={initialSelectedKeys}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        items={items}
      />
    </Layout.Sider>
  );
}
