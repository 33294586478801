import { BACKEND_URL } from "@/constants";

export async function loadWhoami() {
  const response0 = await loadWhoamiInternal();

  if (response0.status === 401) {
    return undefined;
  }

  return response0.json() as Promise<WhoamiResponse>;
}

async function loadWhoamiInternal() {
  return fetch(BACKEND_URL + "/users/whoami", {
    method: "POST",
    credentials: "include",
  });
}

interface WhoamiResponse {
  user: {
    id: number;
    email: string;
    locale: string;
    groups: string[];
  };
}
