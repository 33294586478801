import { BACKEND_URL } from "@/constants";

import resetSession from "./reset-session";

export default async function downloadAttachment(reportUri: string) {
  const response = await fetch(BACKEND_URL + "/" + reportUri, {
    method: "GET",
    credentials: "include",
  });

  if (response.status === 401) {
    resetSession();
    throw new Error("Session expired. Please login again.");
  }

  const contentDisposition = response.headers.get("Content-Disposition");
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const fileName = contentDisposition!.substring(contentDisposition!.indexOf("filename=") + 9);
  const content = await response.blob();
  const link = document.createElement("a");

  link.href = URL.createObjectURL(content);
  link.download = fileName;

  link.click();
  setTimeout(() => URL.revokeObjectURL(link.href));
}
