import { gql, useQuery } from "@apollo/client";
import { Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

interface PastAndFutureAppointmentsModalProps {
  onClose: () => void;
  onClick: (record: object) => void;
  locationId: string;
  relationId: string;
}

export default function PastAndFutureAppointmentsModal({ onClose, onClick, locationId, relationId }: PastAndFutureAppointmentsModalProps) {
  const [offset, setOffset] = useState(0);
  const { data, loading } = useQuery(PastAndFutureAppointmentsQuery, { variables: { locationId, relationId, offset } });

  return (
    <Modal centered onCancel={() => onClose()} footer={null} open title="Onderhoudsbeurten" width="75%">
      <Table
        bordered
        columns={COLUMNS}
        dataSource={data?.pastAndFutureAppointments.edges}
        loading={loading}
        className="table-clickable-rows"
        rowKey={record => `${record.appointmentId}+${record.worksheetId}`}
        onChange={pagination => {
          setOffset(((pagination.current ?? 1) - 1) * PAGE_SIZE);
        }}
        onRow={record => ({
          onClick: () => onClick(record),
        })}
        pagination={{ total: data?.pastAndFutureAppointments.totalCount ?? undefined, pageSize: PAGE_SIZE }}
      />
    </Modal>
  );
}

const PAGE_SIZE = 10;

const COLUMNS: ColumnsType<any> = [
  {
    title: "Datum",
    render: record => dayjs(record.startTime ?? record.prospectiveDate).format("LL"),
    key: "date",
  },
  {
    title: "Tijd",
    key: "time",
    render: (_, record) => {
      // only worksheet is available
      if ((record.startTime === null || record.endTime === null) && record.createdOn !== null && record.completedOn !== null) {
        const fmtActual = `${dayjs(record.createdOn).format("HH:mm")} - ${dayjs(record.completedOn).format("HH:mm")}`;
        return <span style={{ fontStyle: "italic" }}>${fmtActual}</span>;
      }

      // only appointment is available
      if (record.startTime !== null && record.endTime !== null && (record.createdOn === null || record.createdOn === null)) {
        const fmtPlanned = `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`;

        return fmtPlanned;
      }

      // both are available
      if (record.startTime !== null && record.endTime !== null && record.createdOn !== null && record.completedOn !== null) {
        const fmtActual = `${dayjs(record.createdOn).format("HH:mm")} - ${dayjs(record.completedOn).format("HH:mm")}`;
        const fmtPlanned = `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`;

        return (
          <span>
            {fmtActual + " "}
            <span style={{ fontStyle: "italic" }}>({fmtPlanned})</span>
          </span>
        );
      }

      // neither are available
      return null;
    },
  },
  {
    title: "Soort",
    dataIndex: "appointmentType",
    render: appointmentType => appointmentType?.name ?? "-",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: status => (status !== null ? <FormattedMessage id={`appointment.status.${status}`} /> : "-"),
  },
  {
    title: "Medewerker",
    dataIndex: ["employee", "username"],
  },
];

const PastAndFutureAppointmentsQuery = gql`
  query ($relationId: ID!, $locationId: ID!, $offset: Int!) {
    pastAndFutureAppointments(relationId: $relationId, locationId: $locationId, offset: $offset) {
      totalCount
      edges {
        appointmentId
        worksheetId
        startTime
        endTime
        createdOn
        completedOn
        prospectiveDate
        appointmentType {
          id
          name
        }
        employee {
          id
          username
        }
        status
      }
    }
  }
`;
