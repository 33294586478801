import { LoadingOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import { useState } from "react";

import { useAuthentication } from "@/authentication";
import Gravatar from "@/components/Gravatar";
import pngLogo from "@/static/logo.png";

export default function Header() {
  const { user, logoutUser } = useAuthentication();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleOnLogout = async () => {
    setIsLoggingOut(true);
    await logoutUser();
  };

  return (
    <Layout.Header style={{ paddingInline: 12 }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <img
          src={pngLogo}
          style={{
            height: 35,
            filter: "invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(80%)",
          }}
        />
        <div style={{ alignItems: "center", color: "white", display: "flex", flexDirection: "row", gap: 8 }}>
          <Gravatar email={user.email} size={32} style={{ borderRadius: 16 }} />
          <span>{user.email}</span>
          <Button onClick={() => handleOnLogout()} type="text">
            {isLoggingOut ? <LoadingOutlined style={{ color: "white" }} /> : <LogoutOutlined style={{ color: "white" }} />}
          </Button>
        </div>
      </div>
    </Layout.Header>
  );
}
