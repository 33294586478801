import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, Row, Switch } from "antd";
import dayjs from "dayjs";

import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import LocationTransfer from "@/components/LocationTransfer";
import MonthPicker from "@/components/MonthPicker";

import FrequencySelect from "./FrequencySelect";
import RecurrencesPreviewTable from "./RecurrencesPreviewTable";

interface CreateVisitCardProps {
  formIndex: number;
  onRemoveClick: () => void;
  relationId: string;
}

export default function CreateVisitCard({ formIndex, onRemoveClick, relationId }: CreateVisitCardProps) {
  const contractStartingOn = Form.useWatch("startingOn");
  const contractEndingOn = Form.useWatch("endingOn");
  const _startingOn = Form.useWatch(["visits", formIndex, "startingOn"]);
  const _interval = Form.useWatch(["visits", formIndex, "interval"]);
  const _endingOn = Form.useWatch(["visits", formIndex, "endingOn"]);
  const _overrideDefaultFlags = Form.useWatch(["visits", formIndex, "overrideDefaultFlags"]);

  return (
    <Card
      actions={[
        <Button key="delete" danger onClick={() => onRemoveClick()} icon={<DeleteOutlined />} type="text" size="small">
          Verwijderen
        </Button>,
      ]}
      title="Bezoek toevoegen"
    >
      <Row justify="space-between" gutter={44}>
        <Col span={12}>
          <Form.Item labelCol={{ span: 3 }} label="Locaties" name={[formIndex, "locationIds"]} rules={[{ required: true }]}>
            <LocationTransfer relationId={relationId} listStyle={{ width: "50%", minHeight: 300 }} />
          </Form.Item>
          <Form.Item labelCol={{ span: 3 }} label="Opmerking" name={[formIndex, "comment"]}>
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item labelCol={{ span: 4 }} label="Soort afspraak" name={[formIndex, "appointmentTypeId"]} rules={[{ required: true }]}>
            <AppointmentTypePicker />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 4 }}
            label="Eerste afspraak"
            name={[formIndex, "startingOn"]}
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (!value || !contractStartingOn) return Promise.resolve();
                  if ((value as dayjs.Dayjs).isBefore(contractStartingOn)) return Promise.reject("Kan niet voor startdatum contract zijn");

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MonthPicker format="MMMM, YYYY" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 4 }}
            label="Stopzetten per"
            name={[formIndex, "endingOn"]}
            rules={[
              { required: !!contractEndingOn },
              () => ({
                validator(_, value) {
                  if (!value || !contractEndingOn) return Promise.resolve();
                  if ((value as dayjs).isAfter(contractEndingOn)) return Promise.reject(new Error("Moet voor einddatum van contract zijn"));

                  return Promise.resolve();
                },
              }),
            ]}
            // eslint-disable-next-line prettier/prettier
            extra={
              contractEndingOn
                ? `Kan niet later zijn dan de einddatum van het contract (${dayjs(contractEndingOn).format("LL")})`
                : undefined
            }
          >
            <MonthPicker format="MMMM, YYYY" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item labelCol={{ span: 4 }} label="Frequentie" name={[formIndex, "interval"]} rules={[{ required: true }]}>
            <FrequencySelect />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 4 }}
            name={[formIndex, "overrideDefaultFlags"]}
            label="Andere instellingen"
            rules={[{ required: true }]}
          >
            <Switch />
          </Form.Item>
          {_overrideDefaultFlags && (
            <Form.Item labelCol={{ span: 4 }} label="Instellingen">
              <Form.Item name={[formIndex, "flags.FLAG_INCLUDES_DISPOSABLES"]} valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>Inclusief disposables</Checkbox>
              </Form.Item>
              <Form.Item name={[formIndex, "flags.FLAG_INVOICE_AFTERWARDS"]} valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox>Onderhoudsbeurt los factureren (geen abonnement)</Checkbox>
              </Form.Item>
            </Form.Item>
          )}
          {_startingOn && _interval && (
            <div style={{ paddingLeft: 52 }}>
              <RecurrencesPreviewTable
                startingOn={_startingOn.utcOffset(0, true).startOf("month")}
                endingOn={_endingOn ? _endingOn.utcOffset(0, true).startOf("month") : undefined}
                repeat={[(_startingOn as dayjs.Dayjs).month() + 1]}
                interval={_interval}
              />
            </div>
          )}
        </Col>
      </Row>
    </Card>
  );
}
