import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Checkbox, Form, Modal, notification, Skeleton } from "antd";
import dayjs from "dayjs";

import MonthPicker from "@/components/MonthPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import { mapFlagsToFormValues } from "../mapFlagsToFormValues";
import { mapFormValuesToFlags } from "../mapFormValuesToFlags";

interface MutateContractModalProps {
  contractId: string;
  onClose: () => void;
}

export default function MutateContractModal({ contractId, onClose }: MutateContractModalProps) {
  const [formInstance] = Form.useForm();

  const [mutateContractAsync, { loading: isMutateLoading }] = useMutation(MutateContractMutation);
  const [extendContractAsync, { loading: isExtendLoading }] = useMutation(ExtendContractMutation);

  const { data } = useQuery<ContractQueryData>(ContractQuery, { variables: { contractId } });
  const contract = data?.contract;

  const handleOnSubmit = async values => {
    try {
      const [defaultFlags, isFlagsUpdated] = mapFormValuesToFlags(values, "defaultFlags", formInstance.isFieldsTouched);

      await Promise.all([
        isFlagsUpdated ? mutateContractAsync({ variables: { contractId, defaultFlags } }) : Promise.resolve(),
        formInstance.isFieldTouched("endingOn")
          ? extendContractAsync({ variables: { contractId, endingOn: values.endingOn } })
          : Promise.resolve(),
      ]);

      notification.success({ message: "Contract is aangepast" });
      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      confirmLoading={isMutateLoading || isExtendLoading}
      onCancel={onClose}
      onOk={() => formInstance.submit()}
      title="Contract aanpassen"
      open
    >
      {undefined === contract ? (
        <Skeleton />
      ) : (
        <Form
          form={formInstance}
          onFinish={handleOnSubmit}
          layout="vertical"
          initialValues={{
            endingOn: contract.endingOn ? dayjs(contract.endingOn) : undefined,
            ...mapFlagsToFormValues(contract.defaultFlags, "defaultFlags"),
          }}
        >
          <Form.Item label="Standaard instellingen">
            <Form.Item name="defaultFlags.FLAG_INCLUDES_DISPOSABLES" valuePropName="checked" style={{ marginBottom: 0 }}>
              <Checkbox>Inclusief disposables</Checkbox>
            </Form.Item>
            <Form.Item name="defaultFlags.FLAG_INVOICE_AFTERWARDS" valuePropName="checked" style={{ marginBottom: 0 }}>
              <Checkbox>Onderhoudsbeurt los factureren (geen abonnement)</Checkbox>
            </Form.Item>
          </Form.Item>
          {undefined !== contract?.endingOn && (
            <Form.Item label="Einddatum" name="endingOn" help="Laat leeg voor geen einddatum">
              <MonthPicker format="MMMM, YYYY" style={{ width: "100%" }} />
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
}

const ExtendContractMutation = gql`
  mutation ($contractId: ID!, $endingOn: DateTime) {
    extendContract(input: { contractId: $contractId, endingOn: $endingOn }) {
      contract {
        id
        endingOn
      }
    }
  }
`;

const MutateContractMutation = gql`
  mutation ($contractId: ID!, $defaultFlags: [ContractFlag!]) {
    mutateContract(input: { contractId: $contractId, defaultFlags: $defaultFlags }) {
      contract {
        id
        defaultFlags
      }
    }
  }
`;

const ContractQuery = gql`
  query ($contractId: ID) {
    contract(id: $contractId) {
      id
      endingOn
      defaultFlags
    }
  }
`;

interface ContractQueryData {
  contract: {
    id: string;
    endingOn: string | null;
    defaultFlags: string[];
  };
}
