import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import PageHeader from "@/components/PageHeader";
import RelationPicker from "@/components/RelationPicker";

import { DealerTabPane } from "./components/DealerTabPane";
import { PastAndFutureAppointmentsTabPane } from "./components/PastAndFutureAppointmenstTabPane";
import { ProductsTabPane } from "./components/ProductsTabPane";

export function RelationDealerScreen() {
  const navigation = useNavigate();
  const params = useParams() as Record<string, string>;
  const relationIdParam = params?.relationId;

  const handleSelectRelation = (relationId: string) => {
    navigation(`/relations/dealer/${relationId}`);
  };

  return (
    <>
      <PageHeader bordered title="Zoek een dealer">
        <RelationPicker allowClear isDealer onChange={handleSelectRelation} value={relationIdParam} style={{ width: 300 }} />
      </PageHeader>
      {undefined !== relationIdParam && (
        <Tabs
          className="customer-tabs"
          style={{ marginTop: 10 }}
          items={[
            {
              key: "details",
              label: "Dealergegevens",
              children: <DealerTabPane key={relationIdParam} relationId={relationIdParam} />,
            },
            {
              key: "products",
              label: "Geregistreerde producten",
              children: <ProductsTabPane key={relationIdParam} dealerId={relationIdParam} />,
            },
            {
              key: "appointments",
              label: "Afspraken",
              children: <PastAndFutureAppointmentsTabPane key={relationIdParam} dealerId={relationIdParam} />,
            },
          ]}
        />
      )}
    </>
  );
}
