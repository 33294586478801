import { ArrowDownOutlined, ArrowUpOutlined, CarOutlined, DeleteOutlined, PushpinOutlined } from "@ant-design/icons";
import { Avatar, Card, DatePicker, Divider, Form, List, Popover, Switch } from "antd";
import { FormItemProps } from "antd/lib/form";
import dayjs from "dayjs";
import * as React from "react";

import AddressCompleter from "@/components/AddressCompleter";
import AppointmentTypePicker from "@/components/AppointmentTypePicker";

import MarkersContext from "../MarkersContext";
import RelationPicker from "./MultipleRelationPicker";

interface FiltersProps {
  appointmentTypeIds?: string[];
  onClickRoutingMode: () => void;
  onClickVisible: (visible: boolean) => void;
  onChangeAppointmentTypeIds: (value: string[] | undefined) => void;
  onChangePeriod: (value: FiltersProps["period"]) => void;
  onChangeRelationIds: (value: string[] | undefined) => void;
  onChangeIncludeOnlyEmergencyLighting: (value: boolean) => void;
  onChangeIncludeOnlyFireExtinguishers: (value: boolean) => void;
  onChangeIncludeOnlyRequested: (value: boolean) => void;
  onFocusCoordinates: (coordinates: { lat: number; lng: number }) => void;
  includeOnlyEmergencyLighting: boolean;
  includeOnlyFireExtinguishes: boolean;
  includeOnlyRequested: boolean;
  period: [dayjs.Dayjs, dayjs.Dayjs];
  visible: boolean;
}

export default function OptionsPane({
  appointmentTypeIds,
  onClickRoutingMode,
  onClickVisible,
  onChangeAppointmentTypeIds,
  onChangePeriod,
  onChangeRelationIds,
  onChangeIncludeOnlyEmergencyLighting,
  onChangeIncludeOnlyFireExtinguishers,
  onChangeIncludeOnlyRequested,
  onFocusCoordinates,
  includeOnlyEmergencyLighting,
  includeOnlyFireExtinguishes,
  includeOnlyRequested,
  period,
  visible,
}: FiltersProps) {
  const { markers, setMarkerTo } = React.useContext(MarkersContext);
  const [mode, setMode] = React.useState<"filters" | "markers">("filters");

  const handleOnClickVisible = () => {
    setMode("filters");
    onClickVisible(!visible);
  };

  return (
    <Card
      extra={
        <>
          <Popover content="Custom markers">
            <PushpinOutlined onClick={() => setMode(mode === "filters" ? "markers" : "filters")} style={{ marginInline: 6 }} />
          </Popover>
          <Popover content="Afstand tussen twee afspraken ophalen">
            <CarOutlined onClick={() => onClickRoutingMode()} style={{ marginInline: 6, marginRight: 0 }} />
          </Popover>
          <Divider type="vertical" />
          {visible ? <ArrowUpOutlined onClick={handleOnClickVisible} /> : <ArrowDownOutlined onClick={handleOnClickVisible} />}
        </>
      }
      bodyStyle={{
        padding: !visible ? 0 : undefined,
        paddingBottom: !visible ? 0 : undefined,
      }}
      hoverable
      style={{
        position: "absolute",
        borderRadius: 8,
        left: 10,
        top: 10,
        zIndex: 1000,
        width: 350,
      }}
      title="Opties"
    >
      {visible && (
        <>
          {mode === "filters" ? (
            <Form layout="vertical">
              <FilterItem label="Periode" style={{ paddingBottom: 0 }}>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <DatePicker.RangePicker onChange={onChangePeriod as any} value={period as [any, any]} />
              </FilterItem>
              <FilterItem label="Soort afspraak">
                <AppointmentTypePicker
                  allowClear
                  allowSelectCategory
                  showSearch
                  disableDeleted={false}
                  dropdownMatchSelectWidth={false}
                  onChange={value => onChangeAppointmentTypeIds(value.length > 0 ? value : undefined)}
                  mode="multiple"
                  value={appointmentTypeIds}
                />
              </FilterItem>
              <FilterItem label="Relatie">
                <RelationPicker
                  allowClear
                  onClear={() => onChangeRelationIds(undefined)}
                  onChange={value => onChangeRelationIds(value as string[])}
                  // value={relationIds}
                />
              </FilterItem>
              <FilterItem label="Ga naar locatie">
                <AddressCompleter
                  allowClear
                  onSelect={({ geometry }) => {
                    if (undefined === geometry?.location) return;
                    const location = geometry.location;

                    onFocusCoordinates({ lat: location.lat(), lng: location.lng() });
                  }}
                />
              </FilterItem>
              <FilterItem label="Filter ingeroosterde afspraken" labelCol={{ span: 20 }} wrapperCol={{ span: 4 }}>
                <Switch checked={includeOnlyRequested} onChange={onChangeIncludeOnlyRequested} />
              </FilterItem>
              <FilterItem label="Filter locaties met brandblussers" labelCol={{ span: 20 }} wrapperCol={{ span: 4 }}>
                <Switch checked={includeOnlyFireExtinguishes} onChange={onChangeIncludeOnlyFireExtinguishers} />
              </FilterItem>
              <FilterItem label="Filter locaties met noodverlichting" labelCol={{ span: 20 }} wrapperCol={{ span: 4 }}>
                <Switch checked={includeOnlyEmergencyLighting} onChange={onChangeIncludeOnlyEmergencyLighting} />
              </FilterItem>
            </Form>
          ) : Object.keys(markers).length > 0 ? (
            <List itemLayout="horizontal" size="small">
              {Object.entries(markers).map(([relationId, marker], index) => {
                return (
                  <List.Item key={index} actions={[<DeleteOutlined key="delete" onClick={() => setMarkerTo(relationId, undefined)} />]}>
                    <List.Item.Meta
                      avatar={
                        <Avatar>
                          {marker.relationName
                            .split(" ")
                            .map(i => i.charAt(0))
                            .join("")
                            .toUpperCase()}
                        </Avatar>
                      }
                      title={marker.relationName}
                    />
                  </List.Item>
                );
              })}
            </List>
          ) : (
            <span>Geen custom markers toegevoegd</span>
          )}
        </>
      )}
    </Card>
  );
}

function FilterItem(props: FormItemProps & { children: React.ReactNode }) {
  return <Form.Item {...props} style={{ marginBottom: 20, paddingBottom: 0 }} />;
}
