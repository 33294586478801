import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom";

import { useAuthentication } from "@/authentication";
import AdminPanel from "@/routes";
import { AuthenticateScreen } from "@/routes/Authenticate";
import routes from "@/routes/routes";
import Scheduler from "@/routes/Scheduler";

export default function DefibrionApp() {
  return <RouterProvider router={router} />;
}

function RequireAuth() {
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated) {
    const redirect = window.location.pathname + window.location.search;
    return <Navigate to={redirect !== "/" ? `/login?redirect=${redirect}` : "/login"} />;
  }

  return <Outlet />;
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <AuthenticateScreen />,
  },
  {
    path: "/",
    element: <RequireAuth />,
    children: [
      {
        path: "/scheduler",
        element: <Scheduler />,
      },
      {
        path: "/",
        element: <AdminPanel />,
        children: routes,
      },
    ],
  },
]);
