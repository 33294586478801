/* eslint-disable indent */
export default {
  // Languages...
  nl_NL: "Nederlands",
  nl_BE: "Nederlands (België)",
  fr_BE: "Frans (België)",

  // Sidebar titles.
  "sidebar.dashboard": "Dashboard",
  "sidebar.relations": "Relaties",
  "sidebar.relations.import": "Importeren",
  "sidebar.relations.view": "Bekijken",
  "sidebar.appointments": "Afspraken",
  "sidebar.appointments.request": "Aanvragen",
  "sidebar.appointments.pending": "Openstaand",
  "sidebar.appointments.schedule": "Agenda",
  "sidebar.appointments.overdue": "Achterstallig",
  "sidebar.appointments.types": "Afspraak types",
  "sidebar.worksheets": "Onderhoudsbeurten",
  "sidebar.worksheets.list": "Overzicht",
  "sidebar.worksheets.view": "Onderhoudsbeurt bekijken",
  "sidebar.contracts": "Contracten",
  "sidebar.contracts.register": "Registreren",
  "sidebar.contracts.view": "Bekijken",
  "sidebar.warehouse": "Voorraad",
  "sidebar.shipments": "Verzendingen",
  "sidebar.shipments.send": "Nieuwe verzending",
  "sidebar.shipments.view": "Alles bekijken",
  "sidebar.email-templates": "E-mail templates",
  "sidebar.email-templates.list": "Alles",
  "sidebar.users": "Gebruikers",
  "sidebar.users.list": "Overzicht",
  "sidebar.users.register_customer": "Nieuw klant-account",
  "sidebar.users.register_employee": "Nieuwe medewerker",
  "sidebar.products": "Producten",
  "sidebar.products.search": "Zoeken",
  "sidebar.products.add": "Toevoegen",
  "sidebar.reports": "Rapportages",
  "sidebar.issues": "Meldingen",
  "sidebar.issues.list": "Alles bekijken",
  "sidebar.issues.view": "Melding bekijken",
  "sidebar.users.groups": "Gebruikersgroepen",

  // Relation-centered translations..
  "relation.details": "Klantgegevens",
  relation: "Relatie",
  "relation.name": "Naam",
  "relation.place": "Vestigingslocatie",
  "relation.afasCode": "AFAS",
  "relation.locaties": "Locaties",
  "relation.responsibleEmployee": "Verantwoordelijke salesmedewerker",
  "relation.dealer": "Dealer-klant van",
  "relation.numberOfLocations": "Aantal locaties",
  "relation.sync": "Synchronizeren",
  "relation.planningComment": "Planning-notities",
  "relation.onSiteComment": "Buitendienst-notities",

  linkedUsers: "Gekoppelde klant-accounts",
  "linkedUser.logInAsCustomer": "Inloggen als klant",

  locations: "Locaties",
  location: "Locatie",
  "location.name": "Naam",
  "location.notes": "Notitie",
  "location.afasCode": "AFAS",
  "location.address": "Adres",
  "location.address.extraDetails": "Toevoegingen (gebouw, ...)",
  "location.locale": "Taal",
  "location.contactPerson": "Contactpersoon",
  "location.primaryEmail": "Primair e-mail adres",
  "location.secondaryEmail": "Secondair e-mail adres",
  "location.phoneNumber": "Telefoonnummer",
  "location.mobilePhoneNumber": "Mobiel telefoonnummer",
  "location.planningComment": "Planning-notities",
  "location.onSiteComment": "Buitendienst-notities",
  "location.locationIsDeletedWarning": "Let op! Locatie is verwijderd.",

  discardLocation: "Verwijderen",
  editLocation: "Aanpassen",
  registerLocation: "Registreren",
  updateRelation: "Aanpassen",
  moveLocation: "Verhuizen",
  prevLocation: "Oude locatie",
  nextLocation: "Nieuwe locatie",

  contract: "Contract",
  "contract.mutation": "Contract mutatie",
  "contract.details": "Algemene contractgegevens",
  "contract.customerHasNone": "Klant heeft geen lopende contracten.",
  "contract.startDate": "Start datum",
  "contract.endDate": "Eind datum",
  "contract.locations": "Locaties",
  "contract.files": "Toegevoegde bestanden",
  "contract.contractWasRegistered": "Contract geregistreerd!",

  visit: "Repeterende afspraak",
  "visit.notes": "Opmerkingen",
  "visit.recurrence": "Herhalen",
  "visit.startingOn": "Afspraak starten in",
  "visit.endingOn": "Afspraak stopzetten in",
  "visit.appointmentType": "Soort afspraak",
  "visit.frequency": "Frequentie",

  "appointmentType.category": "Categorie",
  "appointmentType.name": "Naam",
  "appointmentType.email": "Email",
  "appointmentType.registerAppointmentType": "Afspraaksoort toevoegen",
  "appointmentType.updateAppointmentType": "Afspraaksoort wijzigen",
  "appointmentType.subTypes": "Subtypes",

  appointments: "Afspraken",
  "appointment.request": "Aanvragen",
  "appointment.relation": "Relatie",
  "appointment.location": "Locatie",
  "appointment.date": "Datum",
  "appointment.time": "Tijd",
  "appointment.prospectiveDate": "Beoogde datum",
  "appointment.comment": "Notitie voor monteur",
  "appointment.plannerComment": "Notitie voor planning",
  "appointment.startTime": "Starttijd",
  "appointment.endTime": "Eindtijd",
  "appointment.appointmentType": "Soort afspraak",
  "appointment.assignedTo": "Toegewezen aan",
  "appointment.status": "Status",
  "appointment.status.STATUS_REQUESTED": "Aangevraagd",
  "appointment.status.STATUS_SCHEDULED": "Ingeroosterd",
  "appointment.status.STATUS_CONFIRMED": "Bevestigd",
  "appointment.status.STATUS_CANCELLED": "Geannuleerd",

  "appointments.regularAppointment": "Overige afspraak",
  "appointments.installationAppointment": "Installatie afspraak",
  "appointments.withSpecificTimeInInvitation": "Tijd aangeven in uitnodiging?",
  "appointment.consumableGoods": "Producten mee te nemen",
  "appointments.showAllText": "Alle afspraken weergeven",

  notes: "Notities",

  products: "Producten",
  "products.summary": "Product-samenvatting",
  product: "Product",
  "product.optionalDescription": "Optionele beschrijving",
  "product.locationDescription": "Ophanglocatie",
  "product.rootCategory": "Categorie",
  "product.productionBatch": "Productie batch",
  "product.replacementDate": "Vervangdatum",
  "product.serialCode": "Serienummer",
  "product.productType": "Product type",
  "product.productType.s": "Type",
  "product.parts": "Onderdelen",
  "product.brand": "Merk",
  "product.route": "Looproute",
  "product.additionalProduct": "Gerelateerd product informatie",
  "product.productType.code": "Artikelcode",
  "product.productType.lightType": "Type lamp",
  "product.productType.battery": "Batterij",
  "product.productType.description": "Beschrijving",
  "product.productType.amount": "Aantal",
  registerProduct: "Nieuw product registreren",
  editProduct: "Product aanpassen",
  "product.category.save": "Opslaan",
  "product.category.name": "Naam categorie",
  "procuct.category.relate": "Koppelen aan",

  maintenance: "Onderhoud",
  "maintenance.productsInMaintenance": "Producten in onderhoud",
  "maintenance.lastCheckedOn": "Laatst gecontroleerd",
  "maintenance.replacementDate": "Vervangdatum",
  "maintenance.installedOn": "Geïnstalleerd op",
  "maintenance.status": "Status",
  "maintenance.status.CONDITION_GOOD": "Goed",
  "maintenance.status.CONDITION_ACTION_REQUIRED": "Actie vereist",
  "maintenance.status.CONDITION_DESTROYED": "Verwijderd",
  "maintenance.status.CONDITION_UNKNOWN": "Onbekend",

  "maintenance.lightType": "Status",
  "maintenance.lightType.LIGHT_TYPE_LED": "LED",
  "maintenance.lightType.LIGHT_TYPE_TL": "TL",

  "maintenance.serviceLevel": "Onderhoudsniveau",
  "maintenance.manufacturingDate": "Bouwdatum",

  "seminar.description": "Training gegeven op {providedOn} door instructeur {instructor}",
  "seminar.seminarType": "Soort training",
  "seminar.providedOn": "Gegeven op",
  "seminar.instructor": "Instructeur",
  "seminar.participants": "Deelnemers",
  "seminar.type.BASIC_COURSE": "BHV-cursus basis",

  persons: "Personen",
  "person.name": "Naam",
  "person.dateOfBirth": "Geboortedatum",
  "person.lastSeminarOn": "Laatste cursus",

  // Shipments..
  shipments: "Verzendingen",
  "shipment.sentOn": "Verzenddatum",
  "shipment.packages": "Aantal pakketten",
  "shipment.weight": "Totaal gewicht",
  "shipment.trackingLink": "Track & Trace",
  cancelShipment: "Weet u zeker dat u deze verzending wil annuleren?",

  // Employee schedule..
  "schedule.work_week": "Werk week",
  "schedule.day": "Dag",
  "schedule.agenda": "Agenda",
  "schedule.showRouteOnScheduler": "Route weergeven op kaart",

  // Multi-functional..
  overwriteReport: "Bestand overschrijven",
  generateDirectInvoice: "Genereer baliedirectfactuur",
  directInvoice: "Baliedirectfactuur",
  exportToExcel: "Exporteren",
  changeIcon: "Icoon wijzigen",
  genericConfirmation: "Weet u het zeker?",
  searchRelationText: "Zoek een klant",
  searchCustomersPlaceholder: "Vul een naam of klantcode in...",
  filterOnRelation: "Filteren op relatie",
  available: "Beschikbaar",
  selected: "Geselecteerd",
  register: "Registreren",
  chooseAdministration: "Kies een administratie",
  customerCode: "Klantnummer",
  scheduleAppointment: "Inroosteren",
  saveChanges: "Wijzigingen opslaan",
  travelTime: "Geschatte reistijd",
  distance: "Afstand",
  view: "Bekijken",
  amount: "Aantal",
  back: "Terug",
  close: "Sluiten",
  delete: "Verwijderen",
  period: "Periode",

  // Issues
  issues: "Meldingen",
  "issue.createdOn": "Gemeld op",
  "issue.createdBy": "Gemeld door",
  "issue.issueType": "Soort melding",
  "issue.status": "Status",
  "issue.subject": "Onderwerp / actie",
  "issue.comments": "Berichten",
  "issue.firstComment": "Bericht",
  "issue.lastCommentedAt": "Laatste reactie geplaatst",
  "issue.publiclyAccessible": "Zichtbaar voor klant",
  "issue.relatedProducts": "Producten",
  "issue.assignTo": "Toewijzen aan",
  "issue.status.STATUS_RAISED": "Aangekaart",
  "issue.status.STATUS_PENDING": "In behandeling",
  "issue.status.STATUS_RESOLVED": "Opgelost",
  "issue.registerIssue": "Melding toevoegen",
  "issue.markIssueResolved": "Melding markeren als opgelost",
  "issue.markIssuePending": "Melding markeren als in behandeling",

  // Orders
  "order.orderNumber": "Ordernummer",
  "order.referenceNumber": "Referentienummer",

  // Worksheets
  worksheets: "Werkzaamheden",
  "worksheet.viewWorksheet": "Onderhoudsbeurt bekijken",
  "worksheet.createdOn": "Datum",
  "worksheet.employee": "Monteur",
  "worksheet.numberOfItems": "Regels",
  "worksheet.numberOfRelatedIssues": "Meldingen",
  "worksheet.files": "Toegevoegde bestanden",
  "worksheet.signedBy": "Getekend door",
  "worksheet.work": "Werkzaamheden",
  "worksheet.work.workType": "Soort werk",
  "worksheet.work.workType.installproductwork": "Installatie",
  "worksheet.work.workType.serviceproductwork": "Onderhoud",
  "worksheet.work.workType.registerproductwork": "Registratie",
  "worksheet.work.workType.replaceproductwork": "Vervanging",
  "worksheet.work.workType.discardproductwork": "Verwijdering",
  "worksheet.work.reasonForDiscarding": "Reden voor verwijderen",
  "worksheet.work.reasonForReplacement": "Reden voor vervanging",
  "worksheet.markWorksheetProcessed": "Markeren als verwerkt?",
  "worksheet.workItems.export": "Exporteer items naar Excel",

  // Headings for the ProductTableContainer.
  "heading.product_details.batch_code": "Batchnummer",
  "heading.description": "Omschrijving",
  "heading.product_details.code": "Artikelnummer",
  "heading.product_details.serial_number": "Serienummer",
  "heading.product_details.production_date": "Productiedatum",
  "heading.num_delivered": "Aantal geleverd",

  // Users..
  "user.userName": "Gebruikersnaam",
  "user.email": "E-mail",
  "user.locale": "Taal",
  "user.lastSeenAt": "Laatste keer gezien",
  "user.isActive": "Actief",
  "user.afasId": "AFAS ID",

  // Usergroups
  "usergroup.GROUP_EMPLOYEES": "Medewerkers",
  "usergroup.GROUP_SERVICE_EMPLOYEES": "Monteurs",
  "usergroup.GROUP_PLANNING": "Planning",
  "usergroup.GROUP_CUSTOMERS": "Klanten",
  "usergroup.planning": "Planning",

  // Resetting password, confirming account..
  confirmAccount: "Account bevestigen",
  choosePassword: "Kies een nieuw wachtwoord..",
  passwordsNotMatching: "Wachtwoorden komen niet overeen!",
  savePassword: "Wachtwoord opslaan",
  forgotPassword: "Wachtwoord vergeten?",
  forgotPasswordError: "Er is iets mis gegaan :(",
  forgotPasswordSend: "Versturen",
  forgotPasswordMandatoryField: "Verplicht",

  // users/employees/register
  createEmployee: "Medewerker-account aanmaken",
  "createEmployee.description":
    "Hier kunt u een nieuwe medewerker toegang geven tot deze app. Vul de velden in. Er wordt een bevestigingsmail verstuurd naar de medewerker waar hij of zij een wachtwoord kan kiezen.",
  "createEmployee.userGroups": "Gebruikersgroepen",

  // users/customers/register
  "createCustomer.visibleLocations": "Zichtbare locaties",
  createCustomer: "Klant-account aanmaken",
  "createCustomer.description":
    "Hier kunt u een gebruikersaccount aanmaken voor het klantportaal. Kies een e-mail adres en gebruikersnaam waar de relatie mee kan inloggen. Een wachtwoord zal automatisch worden aangemaakt en naar de relatie verstuurd.",
};
