import { gql, useMutation, useQuery } from "@apollo/client";
import { Card, Checkbox, Empty, List, Skeleton } from "antd";
import dayjs from "dayjs";
import { useState } from "react";

import { useAuthentication } from "@/authentication";
import { formatAppointmentType } from "@/functions/appointment-util";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

interface AppointmentRemindMeCardProps {
  onClick: (appointmentId: string) => void;
}

export function AppointmentRemindMeCard({ onClick }: AppointmentRemindMeCardProps) {
  // eslint-disable-next-line prettier/prettier
  const { user: { id: userId } } = useAuthentication();
  const [remindAt] = useState(() => dayjs().add(-1, "days"));

  const { data, loading } = useQuery(AppointmentRemindMeQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      remindAt,
      employeeId: userId,
    },
  });

  const [completeAppointmentRemindMe] = useMutation(CompleteAppointmentRemindMe);

  const reminders = data?.appointmentRemindMes ?? [];
  const today = dayjs();
  const tomorrow = today.add(1, "day");

  // eslint-disable-next-line prettier/prettier
  const itemsToday = reminders.filter(x => !x.isCompleted && (dayjs(x.remindAt).isSame(today, "day") || dayjs(x.remindAt).isBefore(today, "day")));
  const itemsTomorrow = reminders.filter(x => !x.isCompleted && dayjs(x.remindAt).isSame(tomorrow, "day"));
  const itemsCompleted = reminders.filter(x => x.isCompleted);
  const itemsLater = reminders.filter(x => !x.isCompleted && dayjs(x.remindAt).isAfter(tomorrow, "day"));

  const handleCompleteItem = async (appointmentRemindMeId: number) => {
    try {
      await completeAppointmentRemindMe({
        variables: { appointmentRemindMeId },
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error);
    }
  };

  const renderItem = item => {
    const time = dayjs(item.appointment.startTime ?? item.appointment.prospectiveDate).format("DD/MM/YYYY");
    const description = formatAppointmentType(item.appointment.appointmentType);
    const relation = formatRelationName(item.appointment.relation);

    return (
      <List.Item onClick={() => onClick(item.appointment.id)} style={{ columnGap: 8 }}>
        <Checkbox
          checked={item.isCompleted}
          disabled={item.isCompleted}
          onChange={event => {
            event.stopPropagation();
            handleCompleteItem(item.id);
          }}
        />
        <List.Item.Meta title={`${time} - ${description} @ ${relation}, ${item.appointment.location.name}`} />
      </List.Item>
    );
  };

  return (
    <Card bodyStyle={{ padding: false === loading ? 0 : undefined }} title="Afspraak herinneringen">
      {loading ? (
        <Skeleton />
      ) : reminders.length > 0 ? (
        <>
          {itemsToday.length > 0 && <List bordered dataSource={itemsToday} header="Vandaag" renderItem={renderItem} />}
          {itemsTomorrow.length > 0 && <List bordered dataSource={itemsTomorrow} header="Morgen" renderItem={renderItem} />}
          {itemsLater.length > 0 && <List bordered dataSource={itemsLater} header="Later" renderItem={renderItem} />}
          {itemsCompleted.length > 0 && <List bordered dataSource={itemsCompleted} header="Afgerond" renderItem={renderItem} />}
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
}

function formatRelationName(relation) {
  return relation.afasCode !== null ? `(${relation.afasCode}) ${relation.name}` : relation.name;
}

const CompleteAppointmentRemindMe = gql`
  mutation ($appointmentRemindMeId: ID!) {
    completeAppointmentRemindMe(input: { appointmentRemindMeId: $appointmentRemindMeId }) {
      appointmentRemindMe {
        id
        isCompleted
      }
    }
  }
`;

const AppointmentRemindMeQuery = gql`
  query ($employeeId: ID!, $remindAt: DateTime) {
    appointmentRemindMes(employeeId: $employeeId, remindAt: $remindAt) {
      id
      appointment {
        id
        appointmentType {
          id
          name
        }
        relation {
          id
          afasCode
          name
        }
        location {
          id
          name
        }
        prospectiveDate
        startTime
        status
      }
      remindAt
      isCompleted
      completedAt
    }
  }
`;
