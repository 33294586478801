const isAppointmentScheduleable = (appointment: Appointment) => {
  return appointment.status === "STATUS_REQUESTED";
};

const isInstallation = (appointment: Appointment) => {
  return rootAppointmentType(appointment.appointmentType).id === "4";
};

const appointmentTime = (appointment: Appointment) => {
  if (isAppointmentScheduleable(appointment)) {
    return appointment.prospectiveDate;
  }

  return appointment.startTime;
};

export function formatAppointmentType(appointmentType: AppointmentType) {
  return appointmentType.name;
}

export function formatAppointmentStatus(status: string) {
  if (status === "STATUS_REQUESTED") {
    return "Aangevraagd";
  } else if (status === "STATUS_SCHEDULED") {
    return "Ingeroosterd";
  } else if (status === "STATUS_CONFIRMED") {
    return "Bevestigd";
  } else if (status === "STATUS_CANCELLED") {
    return "Geannuleerd";
  }

  throw new Error(`Unexpected appointment status: "${status}" `);
}

export function rootAppointmentType(appointmentType: AppointmentType): AppointmentType {
  return appointmentType.category ? rootAppointmentType(appointmentType.category) : appointmentType;
}

export { appointmentTime, isAppointmentScheduleable, isInstallation };

interface AppointmentType {
  id: string;
  name: string;
  category?: AppointmentType;
}

interface Appointment {
  status: string;
  appointmentType: AppointmentType;
  prospectiveDate: string;
  startTime: string | null;
}
