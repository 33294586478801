import { gql } from "@apollo/client";

export default gql`
  mutation (
    $allowMissingFields: Boolean
    $locationId: ID!
    $productTypeId: ID!
    $brand: String
    $serialCode: String
    $productionBatch: String
    $manufacturingDate: DateTime
    $replacementDate: DateTime
    $optionalDescription: String
    $optionalProperties: [StringMapInput!]
    $position: Int
    $locationDescription: String
    $installedOn: DateTime
    $lastCheckedOn: DateTime
    $lastMajorMaintenanceOn: DateTime
    $condition: ProductCondition!
    $parts: [RegisterProductPartInput!]
  ) {
    registerProduct(
      input: {
        allowMissingFields: $allowMissingFields
        locationId: $locationId
        productTypeId: $productTypeId
        serialCode: $serialCode
        productionBatch: $productionBatch
        replacementDate: $replacementDate
        locationDescription: $locationDescription
        optionalDescription: $optionalDescription
        optionalProperties: $optionalProperties
        installedOn: $installedOn
        manufacturingDate: $manufacturingDate
        lastCheckedOn: $lastCheckedOn
        lastMajorMaintenanceOn: $lastMajorMaintenanceOn
        brand: $brand
        condition: $condition
        parts: $parts
        position: $position
      }
    ) {
      product {
        id
      }
    }
  }
`;
