import { CheckOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { Button, Card, DatePicker, Form, notification, Space, UploadFile } from "antd";

import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import AttachmentInput, { filterAttachmentIdentifiers } from "@/components/AttachmentInput";
import LocationPicker from "@/components/LocationPicker";
import MarkdownTextInput from "@/components/MarkdownTextInput";
import RelationPicker from "@/components/RelationPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import { AppointmentConsumableGoodInput, AppointmentConsumableGoodsTable } from "./AppointmentConsumableGoodsTable";

interface RequestAppointmentInput {
  relationId: string;
  locationId: string;
  appointmentTypeId: string;
  prospectiveDate: Date;
  plannerComment: string | null;
  invoiceComment: string | null;
  onSiteComment: string | null;
  consumableGoods?: AppointmentConsumableGoodInput[];
  attachments?: UploadFile[];
}

export default function RequestOtherAppointment() {
  const [form] = Form.useForm();
  const [requestAppointmentAsync, { loading }] = useMutation(RequestAppointmentMutation);

  const handleOnSubmit = async (values: RequestAppointmentInput) => {
    const { attachments, consumableGoods, ...rest } = values;

    try {
      await requestAppointmentAsync({
        variables: {
          ...rest,
          consumableGoods: (consumableGoods ?? []).filter(x => x.productTypeId !== null && x.amount !== null && x.amount > 0),
          attachmentIds: filterAttachmentIdentifiers(attachments ?? []),
        },
      });

      notification.success({
        message: "Afspraak aangevraagd",
        description: "Uw afspraak is aangevraagd.",
      });

      form.resetFields();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Form onFinish={handleOnSubmit} form={form} layout="vertical">
      <Space direction="vertical">
        <Card>
          <Form.Item name="relationId" label="Relatie" required rules={[{ required: true }]}>
            <RelationPicker />
          </Form.Item>
          <Form.Item name="locationId" label="Locatie" required rules={[{ required: true }]}>
            <LocationPicker relationId={Form.useWatch("relationId", form)} />
          </Form.Item>
          <Space wrap={false}>
            <Form.Item name="prospectiveDate" label="Beoogde datum" required rules={[{ required: true }]}>
              <DatePicker />
            </Form.Item>
            <Form.Item name="appointmentTypeId" label="Soort" required rules={[{ required: true }]}>
              <AppointmentTypePicker style={{ minWidth: "250px" }} />
            </Form.Item>
          </Space>
          <Form.Item label="Planning notities" name="plannerComment">
            <MarkdownTextInput />
          </Form.Item>
          <Space direction="vertical" size="small">
            <Form.Item label="Buitendienst notities" name="onSiteComment" style={{ marginBottom: 0 }}>
              <MarkdownTextInput />
            </Form.Item>
            <Form.Item name="attachments" valuePropName="fileList">
              <AttachmentInput />
            </Form.Item>
          </Space>
          <Form.Item label="Facturatie notities" name="invoiceComment">
            <MarkdownTextInput />
          </Form.Item>
        </Card>
        <Card bodyStyle={{ padding: 0 }} title="Producten om mee te nemen">
          <Form.Item name="consumableGoods" noStyle>
            <AppointmentConsumableGoodsTable />
          </Form.Item>
        </Card>
        <Button loading={loading} htmlType="submit" type="primary" icon={<CheckOutlined />}>
          Aanvragen
        </Button>
      </Space>
    </Form>
  );
}

const RequestAppointmentMutation = gql`
  mutation (
    $appointmentTypeId: ID!
    $relationId: ID!
    $locationId: ID!
    $prospectiveDate: DateTime!
    $onSiteComment: String
    $plannerComment: String
    $invoiceComment: String
    $consumableGoods: [AppointmentConsumableGoodInput!]
    $attachmentIds: [ID!]
  ) {
    requestAppointment(
      input: {
        appointmentTypeId: $appointmentTypeId
        relationId: $relationId
        locationId: $locationId
        prospectiveDate: $prospectiveDate
        onSiteComment: $onSiteComment
        plannerComment: $plannerComment
        invoiceComment: $invoiceComment
        consumableGoods: $consumableGoods
        attachmentIds: $attachmentIds
      }
    ) {
      appointment {
        id
      }
    }
  }
`;
