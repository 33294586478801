import { DeleteOutlined, SwapOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Alert, Button, Card, Checkbox, DatePicker, Form, Input } from "antd";
import { useEffect, useRef, useState } from "react";

import ProductTypeConsumablePicker from "@/components/ProductTypeConsumablePicker";

import BrandInput from "./BrandInput";
import OptionalDescriptionInput from "./OptionalDescriptionInput";
import ProductTypeQuery from "./ProductTypeQuery";
import WarnForExistingSerialCode from "./WarnForExistingSerialCode";

interface RegisterProductPartCardProps {
  formIndex: number;
  initialProduct?: any;
  onChangeAllowMissingFields: (value: boolean) => void;
  onRemoveClick: () => void;
  onReplaceClick: () => void;
  parentProductTypeId: string;
  relationId: string;
}

// eslint-disable-next-line prettier/prettier
export default function RegisterProductPartCard({
  formIndex,
  initialProduct,
  onChangeAllowMissingFields,
  onRemoveClick,
  onReplaceClick,
  parentProductTypeId,
  relationId,
}: RegisterProductPartCardProps) {
  const form = Form.useFormInstance();
  const elementRef = useRef<HTMLDivElement>(null);

  const partId = form.getFieldValue(["parts", formIndex, "id"]);
  const productTypeId = Form.useWatch(["parts", formIndex, "productTypeId"], form);
  const condition = form.getFieldValue(["parts", formIndex, "condition"]);
  const previousProductId = form.getFieldValue(["parts", formIndex, "previousProductId"]);
  const isDeleted = condition === "CONDITION_DESTROYED";

  const { data } = useQuery(ProductTypeQuery, {
    variables: { productTypeId },
    skip: undefined === productTypeId || initialProduct?.productType.id === productTypeId,
  });

  const productType =
    undefined !== initialProduct && initialProduct.productType.id === productTypeId ? initialProduct.productType : data?.productType;

  const hasBrand = (productType?.hasBrand ?? false) as boolean;
  const hasSerialCode = typeof productType?.serialCodeExpr === "string";
  const hasProductionBatch = typeof productType?.productionBatchExpr === "string";
  const isExpirationDateRequired = (productType?.isExpirationDateRequired ?? false) as boolean;

  const [isSerialCodeUnknown, setIsSerialCodeUnknown] = useState(false);
  const [isBrandUnknown, setIsBrandUnknown] = useState(false);
  const [isProductionBatchUnknown, setIsProductionBatchUnknown] = useState(false);
  const [isExpirationDateUnknown, setIsExpirationDateUnknown] = useState(false);

  useEffect(() => {
    if (undefined !== partId) return;
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    const allowMissingFields = isSerialCodeUnknown || isBrandUnknown || isProductionBatchUnknown || isExpirationDateUnknown;
    onChangeAllowMissingFields(allowMissingFields);
  }, [isSerialCodeUnknown, isBrandUnknown, isProductionBatchUnknown, isExpirationDateUnknown]);

  useEffect(() => {
    if (undefined === productType) return;

    form.resetFields([
      ["parts", formIndex, "serialCode"],
      ["parts", formIndex, "productionBatch"],
      ["parts", formIndex, "replacementDate"],
      ["parts", formIndex, "lastMajorMaintenanceOn"],
    ]);

    setIsSerialCodeUnknown(hasSerialCode && !form.getFieldValue(["parts", formIndex, "serialCode"]));
    setIsBrandUnknown(hasBrand && !form.getFieldValue(["parts", formIndex, "brand"]));
    setIsProductionBatchUnknown(hasProductionBatch && !form.getFieldValue(["parts", formIndex, "productionBatch"]));
    setIsExpirationDateUnknown(isExpirationDateRequired && !form.getFieldValue(["parts", formIndex, "expirationDate"]));
  }, [productType]);

  return (
    <Card
      ref={elementRef}
      bordered
      size="small"
      title={`Onderdeel #${formIndex + 1}`}
      actions={[
        <Button
          disabled={undefined === initialProduct || isDeleted}
          key="replace"
          icon={<SwapOutlined />}
          onClick={onReplaceClick}
          size="small"
          type="text"
        >
          Vervangen
        </Button>,
        <Button danger key="delete" disabled={isDeleted} icon={<DeleteOutlined />} onClick={onRemoveClick} size="small" type="text">
          Verwijderen
        </Button>,
      ]}
    >
      {(undefined === partId || isDeleted) && (
        <Alert
          showIcon
          message={
            isDeleted
              ? "Je hebt dit onderdeel verwijderd"
              : undefined !== previousProductId
              ? "Je bent dit onderdeel aan het toevegen als vervangproduct"
              : "Je bent dit onderdeel aan het toevoegen"
          }
          type="warning"
          style={{ marginBlockEnd: 12 }}
        />
      )}
      <Form.Item name={[formIndex, "productTypeId"]} label="Product-type" required rules={[{ required: true }]}>
        <ProductTypeConsumablePicker disabled={isDeleted} consumableForId={parentProductTypeId} />
      </Form.Item>
      <FormItemGroup>
        <Form.Item
          name={[formIndex, "brand"]}
          label="Merk/ type"
          style={{ width: "35%" }}
          required={hasBrand}
          help={
            hasBrand && (
              <Checkbox
                checked={isBrandUnknown}
                onChange={() => {
                  setIsBrandUnknown(!isBrandUnknown);
                  if (!isBrandUnknown) form.setFieldValue(["parts", formIndex, "brand"], null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
          rules={[{ required: hasBrand && !isBrandUnknown && !isDeleted }]}
        >
          <BrandInput disabled={!hasBrand || isDeleted} productTypePath={["parts", formIndex, "productTypeId"]} relationId={relationId} />
        </Form.Item>
        <Form.Item
          name={[formIndex, "serialCode"]}
          label="Serienummer"
          style={{ width: "65%" }}
          required={hasSerialCode}
          help={
            hasSerialCode && (
              <Checkbox
                checked={isSerialCodeUnknown}
                onChange={() => {
                  setIsSerialCodeUnknown(!isSerialCodeUnknown);
                  if (!isSerialCodeUnknown) form.setFieldValue(["parts", formIndex, "serialCode"], null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
          rules={
            hasSerialCode
              ? [
                  {
                    pattern: new RegExp((productType.serialCodeExpr as string).slice(1, -1)),
                    message: "Serienummer niet correct",
                  },
                  { required: hasSerialCode && !isSerialCodeUnknown && !isDeleted },
                ]
              : undefined
          }
        >
          <Input disabled={!hasSerialCode || isDeleted} pattern={productType?.serialCodeHint ?? undefined} />
        </Form.Item>
      </FormItemGroup>
      {/* eslint-disable-next-line prettier/prettier */}
      {hasSerialCode && (
        <WarnForExistingSerialCode
          productTypePath={["parts", formIndex, "productTypeId"]}
          serialCodePath={["parts", formIndex, "serialCode"]}
        />
      )}
      <FormItemGroup>
        <Form.Item
          name={[formIndex, "productionBatch"]}
          label="Productiebatch"
          required={hasProductionBatch}
          style={{ width: "34.5%" }}
          help={
            hasProductionBatch && (
              <Checkbox
                checked={isProductionBatchUnknown}
                onChange={() => {
                  setIsProductionBatchUnknown(!isProductionBatchUnknown);
                  if (!isProductionBatchUnknown) form.setFieldValue(["parts", formIndex, "productionBatch"], null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
          rules={
            hasProductionBatch
              ? [
                  {
                    pattern: new RegExp((productType.productionBatchExpr as string).slice(1, -1)),
                    message: "Productiebatch niet correct",
                  },
                  { required: hasProductionBatch && !isProductionBatchUnknown && !isDeleted },
                ]
              : undefined
          }
        >
          <Input disabled={!hasProductionBatch || isDeleted} pattern={productType?.productionBatchHint ?? undefined} />
        </Form.Item>
        <Form.Item
          name={[formIndex, "replacementDate"]}
          label="Vervangdatum"
          required={isExpirationDateRequired}
          help={
            isExpirationDateRequired && (
              <Checkbox
                checked={isExpirationDateUnknown}
                onChange={() => {
                  setIsExpirationDateUnknown(!isExpirationDateUnknown);
                  if (!isExpirationDateUnknown) form.setFieldValue(["parts", formIndex, "expirationDate"], null);
                }}
              >
                Onbekend
              </Checkbox>
            )
          }
          rules={[{ required: isExpirationDateRequired && !isExpirationDateUnknown && !isDeleted }]}
          style={{ flexGrow: 1 }}
        >
          <DatePicker disabled={isDeleted} style={{ width: "100%" }} />
        </Form.Item>
      </FormItemGroup>
      <Form.Item name={[formIndex, "optionalDescription"]} label="Beschrijving">
        <OptionalDescriptionInput
          disabled={isDeleted}
          productTypePath={["parts", formIndex, "productTypeId"]}
          provideSuggestions={!hasBrand && !hasSerialCode && !hasProductionBatch}
          relationId={relationId}
        />
      </Form.Item>
    </Card>
  );
}

function FormItemGroup({ children }: { children: React.ReactNode }) {
  return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>{children}</div>;
}
