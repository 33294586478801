import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import truncate from "lodash/truncate";
import { useCallback, useMemo, useState } from "react";
import Highlighter from "react-highlight-words";

import TableFilterDropdown from "@/components/TableFilterDropdown";
import formatAddress from "@/functions/format-address";
import formatProductDescription from "@/functions/format-product-description";
import simpleTableFilterSorter from "@/functions/simple-table-filter-sorter";

import { useDealerRelations } from "../useDealerRelations";

const PAGE_SIZE = 10;

const STATUS_LIST = [
  { text: "Goed", value: "CONDITION_GOOD" },
  { text: "Actie vereist", value: "CONDITION_ACTION_REQUIRED" },
  { text: "Verwijderd", value: "CONDITION_DESTROYED" },
  { text: "Onbekend", value: "CONDITION_UNKNOWN" },
];

const DEFAULT_FILTERS = {
  relationId: undefined as string | undefined,
  locationId: undefined as string | undefined,
  productCategoryId: undefined as string | undefined,
  productTypeId: undefined as string | undefined,
  serialCode: undefined as string | undefined,
  condition: ["CONDITION_GOOD", "CONDITION_ACTION_REQUIRED", "CONDITION_UNKNOWN"],
};

function collectLocations(locations: Array<{ id: string; name: string; address: object }>) {
  return locations
    .map(l => ({ text: `${l.name} (${formatAddress(l.address as any)})`, value: l.id })) // ..
    .sort(simpleTableFilterSorter);
}

function collectRelations(relations: Array<{ id: string; name: string; afasCode: string | null }>) {
  return relations
    .map(x => ({ value: x.id, text: x.afasCode !== null ? `(${x.afasCode}) ${x.name}` : x.name }))
    .sort(simpleTableFilterSorter);
}

interface ProductsTableProps {
  dealerId: string;
  onClick: (productId: string) => void;
  onCreateClick: () => void;
}

export function ProductsTable({ dealerId, onClick, onCreateClick }: ProductsTableProps) {
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const { data: productsData, loading: productsLoading } = useQuery(ProductsQuery, {
    variables: { ...filters, dealerId, offset, limit: PAGE_SIZE },
  });
  const products = productsData?.products.edges ?? [];

  const { relations, locations } = useDealerRelations(dealerId);

  const columns = useMemo(
    () => [
      {
        title: "Klant",
        key: "customer",
        children: [
          {
            title: "Relatie",
            dataIndex: ["location", "relation"],
            key: "relation",
            filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectRelations(relations)} />,
            render: relation => {
              return relation.afasCode !== null ? `(${relation.afasCode}) ${relation.name}` : relation.name;
            },
          },
          {
            title: "Locatie",
            dataIndex: ["location"],
            key: "location",
            filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectLocations(locations)} />,
            render: location => (
              <>
                <span style={{ display: "block" }}>{location.name}</span>
                <em style={{ display: "block", marginTop: 4 }}>
                  {truncate(formatAddress(location.address), { length: 75, separator: /,? +/ })}
                </em>
              </>
            ),
          },
        ],
      },
      {
        title: "Product",
        key: "product",
        children: [
          {
            title: "Categorie",
            key: "productCategory",
            render: ({ productType }) => productType.rootCategory.name,
          },
          {
            title: "Type",
            key: "productType",
            render: (_, record) => {
              return (
                <span>
                  <span>{formatProductDescription(record)}</span>
                  {record.optionalDescription && <div style={{ fontStyle: "italic" }}>{record.optionalDescription}</div>}
                </span>
              );
            },
          },
          {
            title: "Serienummer",
            dataIndex: "serialCode",
            render: serialCode =>
              undefined !== filters.serialCode && !!serialCode ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[filters.serialCode]}
                  autoEscape
                  textToHighlight={serialCode}
                />
              ) : (
                serialCode
              ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            filterDropdown: props => (
              <TableFilterDropdown
                {...props}
                initialValue={filters.serialCode}
                onClear={() => {
                  setFilters(current => ({
                    ...current,
                    serialCode: undefined,
                  }));
                }}
                onSearch={value => {
                  setFilters(current => ({
                    ...current,
                    serialCode: value,
                  }));
                }}
              />
            ),
          },
        ],
      },
      {
        title: "Onderhoud",
        key: "maintenance",
        children: [
          {
            title: "Laatste controle",
            key: "lastCheckedOn",
            render: product => dayjs(product.lastCheckedOn).format("MM-YYYY"),
          },
          {
            title: "Status",
            dataIndex: "condition",
            filters: STATUS_LIST,
            render: condition => STATUS_LIST.find(s => s.value === condition)?.text ?? "Onbekend",
          },
        ],
      },
    ],
    [locations, relations, products]
  );

  return (
    <Table
      bordered
      columns={columns}
      dataSource={products}
      loading={productsLoading}
      onChange={(pagination, filters) => {
        setOffset(((pagination.current ?? 1) - 1) * (pagination.pageSize ?? PAGE_SIZE));

        setFilters(current => ({
          ...current,
          relationId: (filters["relation"]?.[0] as string) ?? undefined,
          locationId: (filters["location"]?.[0] as string) ?? undefined,
          productCategoryId: (filters["productCategory"]?.[0] as string) ?? undefined,
          productTypeId: (filters["productType"]?.[0] as string) ?? undefined,
          condition: (filters["condition"] as string[]) ?? DEFAULT_FILTERS.condition,
        }));
      }}
      onRow={useCallback(record => ({ onClick: () => onClick(record.id) }), [onClick])}
      rowKey={product => product.id}
      title={() => (
        <Button onClick={onCreateClick} icon={<PlusOutlined />}>
          Product registreren
        </Button>
      )}
      pagination={{ total: productsData?.products.totalCount ?? undefined, pageSize: PAGE_SIZE }}
    />
  );
}

const ProductsQuery = gql`
  query (
    $dealerId: ID!
    $relationId: ID
    $locationId: ID
    $locationDescription: String
    $productCategoryId: ID
    $productTypeId: ID
    $serialCode: String
    $condition: [ProductCondition!]
    $routePositionSort: OrderMode
    $offset: Int!
    $limit: Int!
  ) {
    products(
      dealerId: $dealerId
      relationId: $relationId
      locationId: $locationId
      locationDescription: $locationDescription
      productCategoryId: $productCategoryId
      productTypeId: $productTypeId
      serialCode: $serialCode
      condition: $condition
      routePositionSort: $routePositionSort
      offset: $offset
      limit: $limit
    ) {
      totalCount
      edges {
        id
        productType {
          id
          code
          description
          category {
            id
            name
          }
          rootCategory {
            id
            name
          }
        }
        brand
        serialCode
        locationDescription
        location {
          id
          name
          relation {
            id
            afasCode
            name
          }
          address {
            street
            postalCode
            city
            country
            coordinates {
              latitude
              longitude
            }
          }
        }
        route
        position
        condition
        lastCheckedOn
      }
    }
  }
`;
