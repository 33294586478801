import { gql, useMutation, useQuery } from "@apollo/client";
import dayjs, { Dayjs } from "dayjs";

import { useAuthentication } from "@/authentication";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

export function useAppointmentReminder(appointmentId: string) {
  const { user } = useAuthentication();

  const { data } = useQuery(AppointmentRemindMeQuery, {
    variables: { appointmentId, employeeId: user.id },
  });

  const [completeAppointmentRemindMeAsync] = useMutation(CompleteAppointmentRemindMeMutation);
  const [createAppointmentRemindMeAsync] = useMutation(CreateAppointmentRemindMeMutation);
  const [rescheduleAppointmentRemindMeAsync] = useMutation(RescheduleAppointmentRemindMeMutation);
  const [deleteAppointmentRemindMeAsync] = useMutation(DeleteAppointmentRemindMeMutation);

  const complete = async () => {
    if (reminder === undefined) return;

    try {
      await completeAppointmentRemindMeAsync({
        variables: {
          input: {
            appointmentRemindMeId: reminder.id,
          },
        },
      });
    } catch (error) {
      console.error(error);
      mapGraphQLErrorsToNotifications(error);
    }
  };

  const remindAt = async (value: Dayjs) => {
    try {
      if (reminder === undefined) {
        await createAppointmentRemindMeAsync({
          refetchQueries: [AppointmentRemindMeQuery],
          variables: {
            input: {
              appointmentId,
              remindAt: value,
            },
          },
        });
      } else {
        await rescheduleAppointmentRemindMeAsync({
          variables: {
            input: {
              appointmentRemindMeId: reminder.id,
              remindAt: value,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
      mapGraphQLErrorsToNotifications(error);
    }
  };

  const remove = async () => {
    if (reminder === undefined) return;

    try {
      await deleteAppointmentRemindMeAsync({
        refetchQueries: [AppointmentRemindMeQuery],
        variables: {
          input: {
            appointmentRemindMeId: reminder.id,
          },
        },
      });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error);
    }
  };

  // reminder should be in the future OR not completed yet
  const reminders = data?.appointmentRemindMes?.filter(x => {
    const remindAt = dayjs(x.remindAt);
    const today = dayjs();

    return (remindAt.isSame(today, "date") || remindAt.isAfter(today, "date")) && !x.isCompleted;
  });

  const reminder = reminders?.[0] ?? undefined;

  return [reminder, { remindAt, remove, complete }] as const;
}

const AppointmentRemindMeQuery = gql`
  query ($appointmentId: ID!, $employeeId: ID!) {
    appointmentRemindMes(appointmentId: $appointmentId, employeeId: $employeeId) {
      id
      remindAt
      isCompleted
    }
  }
`;

const CreateAppointmentRemindMeMutation = gql`
  mutation ($input: CreateAppointmentRemindMeInput!) {
    createAppointmentRemindMe(input: $input) {
      appointmentRemindMe {
        id
        remindAt
      }
    }
  }
`;

const RescheduleAppointmentRemindMeMutation = gql`
  mutation ($input: RescheduleAppointmentRemindMeInput!) {
    rescheduleAppointmentRemindMe(input: $input) {
      appointmentRemindMe {
        id
        remindAt
      }
    }
  }
`;

const DeleteAppointmentRemindMeMutation = gql`
  mutation ($input: DeleteAppointmentRemindMeInput!) {
    deleteAppointmentRemindMe(input: $input) {
      message
    }
  }
`;

const CompleteAppointmentRemindMeMutation = gql`
  mutation ($input: CompleteAppointmentRemindMeInput!) {
    completeAppointmentRemindMe(input: $input) {
      appointmentRemindMe {
        id
        isCompleted
        completedAt
      }
    }
  }
`;
