import { gql, useQuery } from "@apollo/client";
import { Button, Table } from "antd";
import { SortOrder } from "antd/es/table/interface";
import dayjs from "dayjs";
import { useState } from "react";
import { rrulestr } from "rrule";

import formatDuration from "@/functions/format-duration";

import CreateScheduledBreakRuleModal from "./components/CreateScheduledBreakRuleModal";
import UpdateScheduledBreakRuleModal from "./components/UpdateScheduledBreakRuleModal";

export default function ScheduledBreaksList() {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>();
  const [selectedBreakRuleId, setSelectedBreakRuleId] = useState<string | undefined>();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);

  const usersQuery = useQuery<UsersQueryData>(UsersQuery, {
    variables: { groupId: "GROUP_SERVICE_EMPLOYEES" },
  });

  const scheduledBreakRulesQuery = useQuery<ScheduledBreakRuleQueryData>(ScheduledBreakRuleQuery, {
    variables: { employeeId: selectedEmployeeId },
    skip: selectedEmployeeId === undefined,
  });

  const handleOnCloseCreateModal = () => {
    setIsCreateModalVisible(false);
    scheduledBreakRulesQuery.refetch();
  };

  return (
    <>
      {selectedBreakRuleId !== undefined && (
        // eslint-disable-next-line prettier/prettier
        <UpdateScheduledBreakRuleModal scheduledBreakRuleId={selectedBreakRuleId} onClose={() => setSelectedBreakRuleId(undefined)} />
      )}
      {isCreateModalVisible && undefined !== selectedEmployeeId && (
        // eslint-disable-next-line prettier/prettier
        <CreateScheduledBreakRuleModal onClose={handleOnCloseCreateModal} employeeId={selectedEmployeeId} />
      )}
      <Table
        bordered
        columns={[{ title: "Gebruiker", dataIndex: "username" }]}
        dataSource={usersQuery.data?.employees ?? []}
        expandable={{
          expandedRowRender: _record => (
            <Table
              bordered
              columns={[
                {
                  title: "Vanaf",
                  dataIndex: "validFrom",
                  render: validFrom => dayjs(validFrom).format("DD MMMM, YYYY"),
                  defaultSortOrder: "descend" as SortOrder,
                  sorter: (a, b) => dayjs(a.validFrom).diff(b.validFrom, "days"),
                },
                {
                  title: "Regel",
                  key: "recurrence",
                  render: value => value.rrule.toText(),
                },
                {
                  title: "Om",
                  key: "startTime",
                  render: value => {
                    return (
                      value.rrule.options.byhour?.[0].toString().padStart(2, "0") +
                      ":" +
                      value.rrule.options.byminute?.[0].toString().padStart(2, "0")
                    );
                  },
                },
                {
                  title: "Duratie",
                  dataIndex: "duration",
                  render: value => formatDuration(value),
                },
                {
                  title: "Gepland tot",
                  dataIndex: "plannedUntil",
                  render: plannedUntil => (plannedUntil ? dayjs(plannedUntil).format("DD MMMM, YYYY") : "-"),
                },
              ]}
              dataSource={mapScheduledBreakRules(scheduledBreakRulesQuery.data?.scheduledBreakRules)}
              loading={scheduledBreakRulesQuery.loading}
              rowKey={element => element.id}
              onRow={record => ({
                onClick: () => setSelectedBreakRuleId(record.id),
              })}
              title={() => <Button onClick={() => setIsCreateModalVisible(true)}>Toevoegen</Button>}
              pagination={false}
              style={{ paddingBlock: 12 }}
            />
          ),
          expandedRowKeys: selectedEmployeeId !== undefined ? [selectedEmployeeId] : [],
          onExpandedRowsChange: values => {
            if (values.length > 0) {
              setSelectedEmployeeId(values[0] as string);
            } else {
              setSelectedEmployeeId(undefined);
            }
          },
        }}
        onRow={record => ({
          onClick: () => setSelectedEmployeeId(record.id),
        })}
        loading={usersQuery.loading}
        title={() => "Pauzeschema per onderhoudsmedewerker"}
        rowKey={item => item.id}
      />
    </>
  );
}

const UsersQuery = gql`
  query ($groupId: String) {
    employees(group: $groupId, skipDisabled: true) {
      id
      email
      username
    }
  }
`;

type UsersQueryData = {
  employees: Array<{
    id: string;
    email: string;
    username: string;
  }>;
};

const ScheduledBreakRuleQuery = gql`
  query ($employeeId: ID!) {
    scheduledBreakRules(employeeId: $employeeId) {
      id
      employee {
        id
        email
      }
      recurrence
      duration
      validFrom
      validUntil
      plannedUntil
    }
  }
`;

type ScheduledBreakRuleQueryData = {
  scheduledBreakRules: Array<{
    id: string;
    employee: {
      id: string;
      email: string;
    };
    recurrence: string;
    duration: string;
    validFrom: string;
    validTo: string | null;
    plannedUntil: string | null;
  }>;
};

function mapScheduledBreakRules(input: ScheduledBreakRuleQueryData["scheduledBreakRules"] | undefined) {
  if (input === null || input === undefined) {
    return [];
  }

  return input.map(element => {
    const rrule = rrulestr(element.recurrence);

    return {
      ...element,
      rrule,
    };
  });
}
