import { Layout } from "antd";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "@/components/Layout/Header";
import SideMenu from "@/components/Layout/SideMenu";
import { APP_NAME } from "@/constants";

import routes from "./routes";

function findBreadcrumb(routes_: typeof routes, routeParts: string[]): string[] {
  const breadcrumbs = routes_.find(route => route.path === routeParts[0]);
  if (breadcrumbs === undefined) return [];

  return [breadcrumbs.name, ...findBreadcrumb(breadcrumbs.children ?? [], routeParts.slice(1))];
}

export default function AdminPortal() {
  const location = useLocation();

  useEffect(() => {
    const breadcrumbs = findBreadcrumb(routes, location.pathname.substring(1).split("/"));
    document.title = APP_NAME + " | " + breadcrumbs.join(" > ");
  }, [location.pathname]);

  return (
    <Layout>
      <Header />
      <Layout>
        <SideMenu />
        <Layout style={{ padding: "12px 12px 12px" }}>
          <Layout.Content style={{ minHeight: window.innerHeight - 124 }}>
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
