import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, DatePickerProps, Form, Typography } from "antd";
import dayjs from "dayjs";

import { useAppointmentReminder } from "@/hooks/use-appointment-reminder";

interface AppointmentRemindMeSidebarProps {
  appointmentId: string;
}

export function AppointmentRemindMeSidebar({ appointmentId }: AppointmentRemindMeSidebarProps) {
  const [reminder, actions] = useAppointmentReminder(appointmentId);

  const handleChangeRemindAt: DatePickerProps["onChange"] = async value => {
    if (value === null) return;
    actions.remindAt(value);
  };

  const remindAt = reminder !== undefined ? dayjs(reminder.remindAt) : null;

  return (
    <Form.Item>
      {reminder !== undefined && (
        <Checkbox checked={reminder?.isCompleted} onChange={() => actions.complete()} style={{ marginInlineEnd: 6 }} />
      )}
      <Typography.Text>Herinner mij op</Typography.Text>
      <DatePicker onChange={handleChangeRemindAt} size="small" style={{ marginInline: 6 }} value={remindAt} />

      {remindAt !== null && (
        <Button onClick={() => actions.remove()} size="small">
          <DeleteOutlined />
        </Button>
      )}
    </Form.Item>
  );
}
