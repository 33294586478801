import { gql, useLazyQuery } from "@apollo/client";
import { Select, SelectProps, Space } from "antd";
import * as React from "react";

import useDebounce from "@/hooks/use-debounce";

interface RelationPickerProps extends Omit<SelectProps, "onChange" | "value"> {
  dealerId?: string;
  isDealer?: boolean;
  onClear?: () => void;
  onChange?: (value: string) => void;
  value?: string;
}

export default function RelationPicker({ dealerId, isDealer, onClear, onChange, value, ...restProps }: RelationPickerProps) {
  const [searchValues, setSearchValues] = React.useState<RelationProps[]>([]);
  const [query, setQuery] = React.useState(undefined !== value && !Number.isNaN(parseInt(value)) ? value : "");
  const debouncedQuery = useDebounce(query);

  const [relationQueryAsync] = useLazyQuery(RelationQuery);
  const [searchQueryAsync, { loading: isSearchLoading }] = useLazyQuery(SearchQuery);

  // query if on initial mount a `value` has been given
  React.useEffect(() => {
    async function main() {
      if (!value || value.length < 1) {
        setQuery("");

        return;
      }

      const response = await relationQueryAsync({ variables: { value } });
      const relation = response.data?.relation;

      if (undefined !== relation) {
        setSearchValues([relation]);
        setQuery(value);
      }
    }

    main();
  }, [value]);

  React.useEffect(() => {
    if (debouncedQuery === value) return;

    const hasSearchQuery = debouncedQuery && (!Number.isNaN(parseInt(debouncedQuery)) || debouncedQuery.length >= 3);
    if (!hasSearchQuery && !dealerId) return;

    async function main() {
      const response = await searchQueryAsync({ variables: { dealerId, isDealer, value: debouncedQuery } });
      const relations = response.data?.relations;

      if (undefined !== relations) {
        setSearchValues(relations);
      }
    }

    main();
  }, [dealerId, debouncedQuery]);

  const handleOnClear = () => {
    setSearchValues([]);
    onClear?.();
  };

  return (
    <Select
      {...restProps}
      defaultActiveFirstOption
      loading={isSearchLoading}
      onClear={handleOnClear}
      onSearch={value => {
        setQuery(value as string);
      }}
      onSelect={value => {
        onChange?.(value as string);
        setQuery(value);
      }}
      options={searchValues.map(value => ({
        value: value.id,
        label: `${value.afasCode ? `(${value.afasCode})` : ""} ${value.name}`.trim(),
        place: value.place,
      }))}
      optionRender={option => (
        <Space>
          <div>{option.label}</div>
          <small>{option.place}</small>
        </Space>
      )}
      filterOption={false}
      suffixIcon={null}
      showSearch={dealerId === undefined}
      value={query}
    />
  );
}

interface RelationProps {
  id: string;
  name: string;
  place: string;
  afasCode: string | null;
}

const RelationQuery = gql`
  query ($value: ID!) {
    relation(id: $value) {
      id
      name
      place
      afasCode
    }
  }
`;

const SearchQuery = gql`
  query ($value: String!, $dealerId: ID, $isDealer: Boolean) {
    relations(searchParam: $value, dealerId: $dealerId, isDealer: $isDealer) {
      id
      name
      place
      afasCode
    }
  }
`;
