import { AutoComplete, AutoCompleteProps, Form } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { BaseSelectRef } from "rc-select";
import { forwardRef, useMemo, useState } from "react";

import useSuggestions from "./use-suggestions";

interface BrandInputProps {
  productTypePath: NamePath;
  relationId: string;
}

function collectSuggestions(input: Array<{ productTypeId?: string; brand?: string }>, productTypeId: string) {
  return input
    .filter(i => i.productTypeId === productTypeId)
    .map(i => i.brand)
    .filter(v => undefined !== v && null !== v) as string[];
}

function BrandInput(props: AutoCompleteProps & BrandInputProps, ref?: React.Ref<BaseSelectRef>) {
  const { onFocus, productTypePath, relationId, ...restProps } = props;

  const form = Form.useFormInstance();
  const productTypeId = Form.useWatch(productTypePath, form);

  const { data, loading } = useSuggestions({ relationId, productTypeId, field: "brand" });
  const [options, setOptions] = useState<string[]>([]);

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const parts = form.getFieldValue("parts") ?? [];
    setOptions(collectSuggestions(parts, productTypeId));

    onFocus?.(event);
  };

  const handleOnFilter: AutoCompleteProps["filterOption"] = (input, option) => {
    return (option?.label as string).toLocaleLowerCase().includes(input.toLocaleLowerCase());
  };

  const suggestions = useMemo(() => {
    const onlineSuggestions = data?.productsSuggestions ?? [];
    return [...onlineSuggestions, ...options].map(o => ({ value: o, label: o }));
  }, [data, loading, options]);

  return <AutoComplete {...restProps} onFocus={handleOnFocus} ref={ref} filterOption={handleOnFilter} options={suggestions} showSearch />;
}

export default forwardRef(BrandInput);
