import { ApolloError, gql, useMutation } from "@apollo/client";
import { Form, Modal, notification } from "antd";

import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import { RelationQuery } from "../../graphql/RelationQuery";
import LocationForm from "./EditLocationModal/LocationForm";

interface RegisterLocationModalProps {
  onClose: () => void;
  relationId: string;
}

export default function RegisterLocationModal({ onClose, relationId }: RegisterLocationModalProps) {
  const [form] = Form.useForm();
  const [registerLocationAsync, { loading: isRegistering }] = useMutation(RegisterLocationMutation, {
    refetchQueries: [RelationQuery],
  });

  const handleOnSubmit = async values => {
    try {
      await registerLocationAsync({
        variables: {
          ...values,
          relationId,
          addressExtraDetails: undefined,
          address: { ...values.address, extraDetails: values.addressExtraDetails },
        },
      });

      notification.success({ message: "Locatie toegevoegd" });
      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      bodyStyle={{
        overflowY: "auto",
        maxHeight: window.innerHeight - 168,
      }}
      centered
      confirmLoading={isRegistering}
      onCancel={onClose}
      onOk={() => form.submit()}
      open
      title="Locatie toevoegen"
    >
      <LocationForm form={form} onFinish={handleOnSubmit} showAddressCorrectionHint={false} />
    </Modal>
  );
}

const RegisterLocationMutation = gql`
  mutation (
    $relationId: ID!
    $name: String!
    $afasCode: Int
    $contactPerson: String!
    $primaryEmail: String
    $secondaryEmail: String
    $phoneNumber: String
    $mobilePhoneNumber: String
    $locale: String!
    $planningComment: String
    $onSiteComment: String
    $invoiceComment: String
    $address: AddressInput!
  ) {
    registerLocation(
      input: {
        relationId: $relationId
        name: $name
        afasCode: $afasCode
        contactPerson: $contactPerson
        primaryEmail: $primaryEmail
        secondaryEmail: $secondaryEmail
        phoneNumber: $phoneNumber
        mobilePhoneNumber: $mobilePhoneNumber
        locale: $locale
        planningComment: $planningComment
        onSiteComment: $onSiteComment
        invoiceComment: $invoiceComment
        address: $address
      }
    ) {
      location {
        id
        name
        afasCode
        contactPerson
        primaryEmail
        secondaryEmail
        phoneNumber
        mobilePhoneNumber
        address {
          street
          postalCode
          city
          country
        }
        planningComment
        onSiteComment
        invoiceComment
        locale
      }
    }
  }
`;
