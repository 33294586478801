import { SearchOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";

const COLUMNS: ColumnsType<PastAndFutureAppointmentsOverviewQueryData["pastAndFutureAppointmentsOverview"][number]> = [
  {
    title: "Datum",
    render: (_, record) => dayjs(record.startTime ?? record.createdOn ?? record.prospectiveDate).format("LL"),
    key: "date",
  },
  {
    title: "Tijd",
    key: "time",
    render: (_, record) => {
      // only worksheet is available
      if ((record.startTime === null || record.endTime === null) && record.createdOn !== null && record.completedOn !== null) {
        const fmtActual = `${dayjs(record.createdOn).format("HH:mm")} - ${dayjs(record.completedOn).format("HH:mm")}`;
        return <span style={{ fontStyle: "italic" }}>${fmtActual}</span>;
      }

      // only appointment is available
      if (record.startTime !== null && record.endTime !== null && (record.createdOn === null || record.completedOn === null)) {
        const fmtPlanned = `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`;

        return fmtPlanned;
      }

      // both are available
      if (record.startTime !== null && record.endTime !== null && record.createdOn !== null && record.completedOn !== null) {
        const fmtActual = `${dayjs(record.createdOn).format("HH:mm")} - ${dayjs(record.completedOn).format("HH:mm")}`;
        const fmtPlanned = `${dayjs(record.startTime).format("HH:mm")} - ${dayjs(record.endTime).format("HH:mm")}`;

        return (
          <span>
            {fmtActual + " "}
            <span style={{ fontStyle: "italic" }}>({fmtPlanned})</span>
          </span>
        );
      }

      // neither are available
      return null;
    },
  },
  {
    title: "Soort",
    dataIndex: "appointmentType",
    render: appointmentType => appointmentType?.name ?? "-",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: status => (status !== null ? <FormattedMessage id={`appointment.status.${status}`} /> : "-"),
  },
];

interface AppointmentsCardProps {
  onClick: (record: object) => void;
  onMoreClick: () => void;
  locationId: string;
  relationId: string;
}

export default function AppointmentsCard({ onClick, onMoreClick, locationId, relationId }: AppointmentsCardProps) {
  const { data, loading } = useQuery<PastAndFutureAppointmentsOverviewQueryData>(PastAndFutureAppointmentsOverviewQuery, {
    variables: { relationId, locationId },
  });

  return (
    <Card
      bodyStyle={{ padding: 0, margin: -1 }}
      title="Onderhoudsbeurten"
      actions={[
        <span key="list" role="button" onClick={onMoreClick}>
          <SearchOutlined />
          Alles bekijken
        </span>,
      ]}
    >
      <Table
        loading={loading}
        className="table-clickable-rows"
        rowKey={record => `${record.appointmentId}+${record.worksheetId}`}
        bordered
        columns={COLUMNS}
        dataSource={data?.pastAndFutureAppointmentsOverview ?? []}
        pagination={false}
        onRow={record => ({
          onClick: () => onClick(record),
        })}
      />
    </Card>
  );
}

interface PastAndFutureAppointmentsOverviewQueryData {
  pastAndFutureAppointmentsOverview: Array<{
    appointmentId: string | null;
    worksheetId: string | null;
    startTime: string | null;
    endTime: string | null;
    createdOn: string | null;
    completedOn: string | null;
    prospectiveDate: string | null;
    appointmentType: {
      id: string;
      name: string;
    } | null;
    status: string | null;
  }>;
}

const PastAndFutureAppointmentsOverviewQuery = gql`
  query ($relationId: ID!, $locationId: ID!) {
    pastAndFutureAppointmentsOverview(relationId: $relationId, locationId: $locationId) {
      appointmentId
      worksheetId
      startTime
      endTime
      createdOn
      completedOn
      prospectiveDate
      appointmentType {
        id
        name
      }
      status
    }
  }
`;
