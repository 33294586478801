import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import PageHeader from "@/components/PageHeader";
import RelationPicker from "@/components/RelationPicker";

import { LinkedUsersTabPane } from "./components/LinkedUsersTabPane";
import { ProductsTabPane } from "./components/ProductsTabPane";
import { RelationTabPane } from "./components/RelationTabPane";

export function RelationCustomerScreen() {
  const navigation = useNavigate();
  const params = useParams() as Record<string, string>;

  const relationIdParam = params?.relationId;
  const locationIdParam = params?.locationId;

  // Redirect old URLs
  if (relationIdParam !== undefined && relationIdParam.startsWith("view")) {
    const searchParams = new URLSearchParams(window.location.search);
    const relationId = searchParams.get("relationId");
    const locationId = searchParams.get("locationId");

    if (relationId !== null && locationId !== null) {
      navigation(`/relations/customer/${relationId}/locations/${locationId}`);
      return null;
    }
  }

  const handleSelectRelation = (relationId: string) => {
    navigation(`/relations/customer/${relationId}`);
  };

  const handleSelectLocation = (locationId: string) => {
    if (relationIdParam === undefined) {
      return;
    }

    navigation(`/relations/customer/${relationIdParam}/locations/${locationId}`, {
      replace: locationIdParam === undefined,
    });
  };

  return (
    <>
      <PageHeader bordered title="Zoek een relatie">
        <RelationPicker allowClear onChange={handleSelectRelation} value={relationIdParam} style={{ width: 300 }} />
      </PageHeader>
      {undefined !== relationIdParam && (
        <Tabs
          className="customer-tabs"
          style={{ marginTop: 10 }}
          items={[
            {
              key: "details",
              label: "Klantgegevens",
              children: (
                <RelationTabPane
                  key={relationIdParam}
                  onChangeLocation={handleSelectLocation}
                  relationId={relationIdParam}
                  locationId={locationIdParam}
                />
              ),
            },
            {
              key: "products",
              label: "Geregistreerde producten",
              children: <ProductsTabPane key={relationIdParam} relationId={relationIdParam} />,
            },
            {
              key: "users",
              label: "Gekoppelde gebruikers",
              children: <LinkedUsersTabPane key={relationIdParam} relationId={relationIdParam} />,
            },
          ]}
        />
      )}
    </>
  );
}
