import { FormOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Button, Card, Descriptions } from "antd";
import { rrulestr } from "rrule";

import { formatAppointmentType } from "@/functions/appointment-util";

import prepareIssueComment from "../prepare-issue-comment";

interface CondensedVisitInfoProps {
  onCreateIssueClick: (value: Record<string, unknown>) => void;
  worksheetId: string;
}

export default function CondensedVisitInfo({ onCreateIssueClick, worksheetId }: CondensedVisitInfoProps) {
  const { data, loading } = useQuery(WorksheetQuery, { variables: { worksheetId } });

  const worksheet = data?.worksheet;
  const visit = worksheet?.appointment?.visit;
  const contract = visit?.contract;
  const resolvedFlags = (visit?.overrideDefaultFlags ? visit?.flags : contract?.defaultFlags) ?? [];

  const handleCreateIssueWithComment = (comment: string) => {
    onCreateIssueClick({
      relationId: worksheet.relation.id,
      locationId: worksheet.location.id,
      relatedAppointmentId: worksheet.appointment?.id,
      comment: prepareIssueComment(comment, worksheet.employee.username),
    });
  };

  return (
    <Card loading={loading} title="Afspraak en bezoek" bodyStyle={{ padding: 0, margin: "0 -1px -1px -1px" }}>
      {undefined !== data && (
        <Descriptions bordered layout="vertical">
          <Descriptions.Item label="Frequentie">{visit?.recurrence ? rrulestr(visit?.recurrence).toText() : "-"}</Descriptions.Item>
          <Descriptions.Item label="Inclusief disposables">
            {visit !== undefined ? (resolvedFlags.includes("FLAG_INCLUDES_DISPOSABLES") ? "Ja" : "Nee") : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Onderhoudsbeurt los factureren (geen abonnement)">
            {visit !== undefined ? (resolvedFlags.includes("FLAG_INVOICE_AFTERWARDS") ? "Ja" : "Nee") : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Soort afspraak" span={2}>
            {data.worksheet.appointment ? formatAppointmentType(data.worksheet.appointment.appointmentType) : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Dealer-klant">
            {data.worksheet.relation.dealer ? `(${data.worksheet.relation.dealer.afasCode}) ${data.worksheet.relation.dealer.name}` : "Nee"}
          </Descriptions.Item>
          {data.worksheet.relation.invoiceComment && (
            <Descriptions.Item label="Facturatie opmerking (relatie)" span={3}>
              {data.worksheet.relation.invoiceComment}
            </Descriptions.Item>
          )}
          {data.worksheet.location.invoiceComment && (
            <Descriptions.Item label="Facturatie opmerking (locatie)" span={3}>
              {data.worksheet.location.invoiceComment}
            </Descriptions.Item>
          )}
          {data.worksheet.appointment?.invoiceComment && (
            <Descriptions.Item label="Facturatie opmerking (afspraak)" span={3}>
              {data.worksheet.appointment.invoiceComment}
            </Descriptions.Item>
          )}
          {data.worksheet.publicComment && (
            <Descriptions.Item
              label={
                <div onClick={() => handleCreateIssueWithComment(data.worksheet.publicComment)}>
                  <span style={{ marginInlineEnd: 4 }}>Opmerking publiek (werkbon)</span>
                  <Button icon={<FormOutlined />} type="text" size="small" />
                </div>
              }
              span={3}
            >
              {data.worksheet.publicComment}
            </Descriptions.Item>
          )}
          {data.worksheet.internalComment && (
            <Descriptions.Item
              label={
                <div onClick={() => handleCreateIssueWithComment(data.worksheet.internalComment)}>
                  <span style={{ marginInlineEnd: 4 }}>Opmerking intern (werkbon)</span>
                  <Button icon={<FormOutlined />} type="text" size="small" />
                </div>
              }
              span={3}
            >
              {data.worksheet.internalComment}
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
    </Card>
  );
}

const WorksheetQuery = gql`
  query ($worksheetId: ID!) {
    worksheet(id: $worksheetId) {
      id
      relation {
        id
        invoiceComment
        dealer {
          id
          afasCode
          name
        }
      }
      location {
        id
        invoiceComment
      }
      appointment {
        id
        appointmentType {
          id
          name
          category {
            id
            name
          }
        }
        visit {
          id
          recurrence
          flags
          overrideDefaultFlags
          contract {
            id
            defaultFlags
          }
        }
        invoiceComment
      }
      employee {
        id
        username
      }
      publicComment
      internalComment
    }
  }
`;
