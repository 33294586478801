import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { Button, Card, DatePicker, Form, Input, notification, Space, UploadFile } from "antd";
import debounce from "lodash/debounce";
import { useMemo } from "react";

import AdministrationPicker from "@/components/AdministrationPicker";
import AppointmentTypePicker from "@/components/AppointmentTypePicker";
import AttachmentInput, { filterAttachmentIdentifiers } from "@/components/AttachmentInput";
import LocationPicker from "@/components/LocationPicker";
import MarkdownTextInput from "@/components/MarkdownTextInput";
import RelationPicker from "@/components/RelationPicker";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import { AppointmentConsumableGoodInput, AppointmentConsumableGoodsTable } from "./AppointmentConsumableGoodsTable";

interface RequestAppointmentInput {
  relationId: string;
  locationId: string;
  appointmentTypeId: string;
  prospectiveDate: Date;
  plannerComment: string | null;
  invoiceComment: string | null;
  onSiteComment: string | null;
  consumableGoods?: AppointmentConsumableGoodInput[];
  attachments?: UploadFile[];
}

export default function RequestInstallationAppointment() {
  const [form] = Form.useForm();

  const [importOrderAsync, { error: importOrderError, called: importedOrder, loading: importingOrder }] = useMutation(ImportOrderMutation);
  const [requestAppointmentAsync, { loading }] = useMutation(RequestAppointmentMutation);

  const handleOnChangeOrderNumber = useMemo(
    () =>
      debounce(async () => {
        try {
          const administrationId = form.getFieldValue("administrationId");
          const orderNumber = parseInt(form.getFieldValue("orderNumber"), 10);

          if (!administrationId || !orderNumber || Number.isNaN(orderNumber)) {
            return;
          }

          const response = await importOrderAsync({
            variables: {
              administrationId,
              orderNumber,
            },
          });

          if (response.data === undefined) throw new Error("Could not fetch order information");
          const order = response.data.importOrder.order;

          form.setFieldsValue({
            relationId: order.relation.id,
            locationId: order.location.id,
            consumableGoods: order.orderLines.map(x => ({
              productTypeId: x.productType.id,
              amount: x.amount,
            })),
          });
        } catch (error) {
          if (error instanceof ApolloError) {
            mapGraphQLErrorsToNotifications(error as ApolloError);
          } else if (error instanceof Error) {
            notification.error({
              message: "Fout tijdens het ophalen van de gegevens.",
              description: error.message,
            });
          }
        }
      }, 1000),
    []
  );

  const handleOnSubmit = async (values: RequestAppointmentInput) => {
    const { attachments, consumableGoods, ...rest } = values;

    try {
      await requestAppointmentAsync({
        variables: {
          ...rest,
          consumableGoods: (consumableGoods ?? []).filter(x => x.productTypeId !== null && x.amount !== null && x.amount > 0),
          attachmentIds: filterAttachmentIdentifiers(attachments ?? []),
        },
      });

      notification.success({
        message: "Afspraak aangevraagd",
        description: "Uw afspraak is aangevraagd.",
      });

      form.resetFields();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Form onFinish={handleOnSubmit} form={form} layout="vertical">
      <Space direction="vertical">
        <Card>
          <Space direction="horizontal">
            <Form.Item name="administrationId" label="Administratie" required rules={[{ required: true }]}>
              <AdministrationPicker style={{ minWidth: "200px" }} />
            </Form.Item>
            <Form.Item name="orderNumber" label="Ordernummer" required rules={[{ required: true }]}>
              <Input
                className="input-number-hidden-spinbox"
                type="number"
                min={1}
                addonBefore="#"
                onKeyUp={handleOnChangeOrderNumber}
                suffix={importingOrder ? <LoadingOutlined /> : <span />}
              />
            </Form.Item>
          </Space>
          <Form.Item name="relationId" label="Relatie" required rules={[{ required: true }]}>
            <RelationPicker disabled />
          </Form.Item>
          <Form.Item name="locationId" label="Locatie" required rules={[{ required: true }]}>
            <LocationPicker relationId={Form.useWatch("relationId", form)} />
          </Form.Item>
          <Form.Item name="appointmentTypeId" label="Soort" required rules={[{ required: true }]}>
            <AppointmentTypePicker />
          </Form.Item>
          <Form.Item name="prospectiveDate" label="Beoogde datum" required rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item name="plannerComment" label="Planning notities">
            <MarkdownTextInput />
          </Form.Item>
          <Space direction="vertical" size="small">
            <Form.Item label="Buitendienst notities" name="onSiteComment" style={{ marginBottom: 0 }}>
              <MarkdownTextInput />
            </Form.Item>
            <Form.Item name="attachments" valuePropName="fileList">
              <AttachmentInput />
            </Form.Item>
          </Space>
          <Form.Item label="Facturatie notities" name="invoiceComment">
            <MarkdownTextInput />
          </Form.Item>
        </Card>
        {importedOrder && importOrderError === undefined && (
          <>
            <Card bodyStyle={{ padding: 0 }} title="Producten om mee te nemen">
              <Form.Item name="consumableGoods" noStyle>
                <AppointmentConsumableGoodsTable />
              </Form.Item>
            </Card>
            <Button loading={loading} htmlType="submit" type="primary" icon={<CheckOutlined />}>
              Aanvragen
            </Button>
          </>
        )}
      </Space>
    </Form>
  );
}

const ImportOrderMutation = gql`
  mutation ($administrationId: ID!, $orderNumber: Int!) {
    importOrder(input: { administrationId: $administrationId, orderNumber: $orderNumber }) {
      order {
        relation {
          id
        }
        location {
          id
        }
        orderLines {
          productType {
            id
          }
          amount
        }
      }
    }
  }
`;

const RequestAppointmentMutation = gql`
  mutation (
    $appointmentTypeId: ID!
    $relationId: ID!
    $locationId: ID!
    $prospectiveDate: DateTime!
    $onSiteComment: String
    $plannerComment: String
    $invoiceComment: String
    $attachmentIds: [ID!]
    $consumableGoods: [AppointmentConsumableGoodInput!]
  ) {
    requestAppointment(
      input: {
        appointmentTypeId: $appointmentTypeId
        relationId: $relationId
        locationId: $locationId
        prospectiveDate: $prospectiveDate
        onSiteComment: $onSiteComment
        plannerComment: $plannerComment
        invoiceComment: $invoiceComment
        consumableGoods: $consumableGoods
        attachmentIds: $attachmentIds
      }
    ) {
      appointment {
        id
      }
    }
  }
`;
