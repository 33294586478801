import { Card } from "antd";
import * as React from "react";

import downloadAttachment from "@/functions/download-attachment";

import DownloadButton from "./DownloadButton";

export default function ProductsSpreadsheet() {
  return (
    <Card title="Alle producten in onderhoud">
      <DownloadButton type="primary" onClick={() => downloadAttachment("reports/serviced-products")}>
        Downloaden
      </DownloadButton>
    </Card>
  );
}
