import * as L from "leaflet/dist/leaflet-src";

import { appointmentTime } from "@/functions/appointment-util";

import { MarkerType } from "../MarkersContext";
import mapAppointmentToColor from "./map-to-color";

export function createCircleIcon(appointment: any, opacity: number, selected: boolean) {
  let iconSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="55">';
  // eslint-disable-next-line prettier/prettier
  iconSvg += `<circle fill="${selected ? "white" : mapAppointmentToColor(appointment)}" fill-opacity="${
    opacity / 100
  }" stroke="black" cx="12.5" cy="26.188" r="12.063" />`;

  if (appointment.status === "STATUS_REQUESTED") {
    iconSvg =
      // eslint-disable-next-line prettier/prettier
      `${iconSvg}  <text fill="${
        selected ? "black" : "white"
      }" font-family="Roboto, sans-serif" font-size="14" alignment-baseline="middle" text-anchor="middle">` +
      `    <tspan x="49%" y="55%">${new Date(appointmentTime(appointment)).getMonth() + 1}</tspan>` +
      "  </text>" +
      "</svg>";
  } else {
    iconSvg =
      `${iconSvg}  <g fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10">` +
      '    <path d="M6.319 28.305l3.48 3.479" stroke-linecap="square"/>' +
      '    <path d="M9.799 31.784l10.459-10.459"/>' +
      "  </g>" +
      "</svg>";
  }

  return L.icon({
    iconUrl: `data:image/svg+xml;utf8,${iconSvg}`,
    iconSize: [25, 55],
    iconAnchor: [12, 55],
  });
}

export function createStarIcon(appointment, opacity: number, selected: boolean) {
  let iconSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="55">';
  // eslint-disable-next-line prettier/prettier
  iconSvg += `<polygon fill="${selected ? "white" : mapAppointmentToColor(appointment)}" fill-opacity="${
    opacity / 100
  }" stroke="black" points="12.375,45.25 1.288,26.376 12.625,7.125 23.712,25.999 " />`;

  if (appointment.status === "STATUS_REQUESTED") {
    iconSvg +=
      // eslint-disable-next-line prettier/prettier
      `  <text fill="${
        selected ? "black" : "white"
      }" font-family="Roboto, sans-serif" font-size="14" alignment-baseline="middle" text-anchor="middle">` +
      `    <tspan x="49%" y="55%">${new Date(appointmentTime(appointment)).getMonth() + 1}</tspan>` +
      "  </text>" +
      "</svg>";
  } else {
    iconSvg +=
      `  <g fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10">` +
      '    <path d="M6.319 28.305l3.48 3.479" stroke-linecap="square"/>' +
      '    <path d="M9.799 31.784l10.459-10.459"/>' +
      "  </g>" +
      "</svg>";
  }

  return L.icon({
    iconUrl: `data:image/svg+xml;utf8,${iconSvg}`,
    iconSize: [25, 55],
    iconAnchor: [12, 55],
  });
}

export function createSquareIcon(appointment, opacity: number, selected: boolean) {
  let iconSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="55">';
  // eslint-disable-next-line prettier/prettier
  iconSvg += `<rect x="1.563" y="15.25" fill="${selected ? "white" : mapAppointmentToColor(appointment)}" fill-opacity="${
    opacity / 100
  }" stroke="black" width="21.875" height="21.875" />`;

  if (appointment.status === "STATUS_REQUESTED") {
    iconSvg +=
      // eslint-disable-next-line prettier/prettier
      `  <text fill="${
        selected ? "black" : "white"
      }" font-family="Roboto, sans-serif" font-size="14" alignment-baseline="middle" text-anchor="middle">` +
      `    <tspan x="49%" y="55%">${new Date(appointmentTime(appointment)).getMonth() + 1}</tspan>` +
      "  </text>" +
      "</svg>";
  } else {
    iconSvg +=
      `  <g fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10">` +
      '    <path d="M6.319 28.305l3.48 3.479" stroke-linecap="square"/>' +
      '    <path d="M9.799 31.784l10.459-10.459"/>' +
      "  </g>" +
      "</svg>";
  }

  return L.icon({
    iconUrl: `data:image/svg+xml;utf8,${iconSvg}`,
    iconSize: [25, 55],
    iconAnchor: [12, 55],
  });
}

function createHexagonIcon(appointment: any, opacity: number, selected: boolean) {
  let iconSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="35" height="60">';
  // eslint-disable-next-line prettier/prettier
  iconSvg += `<polygon fill="${
    selected ? "white" : mapAppointmentToColor(appointment)
  }" stroke="black" points="4.656,40.086 -3.188,26.5 4.656,12.914 20.344,12.914 28.188,26.5 20.344,40.086" fill-opacity="${
    opacity / 100
  }"/>`;

  if (appointment.status === "STATUS_REQUESTED") {
    iconSvg +=
      `  <text fill="white" font-family="Roboto, sans-serif" font-size="14" alignment-baseline="middle" text-anchor="middle">` +
      `    <tspan x="49%" y="55%">${new Date(appointmentTime(appointment)).getMonth() + 1}</tspan>` +
      "  </text>" +
      "</svg>";
  } else {
    iconSvg +=
      `  <g fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10">` +
      '    <path d="M6.319 28.305l3.48 3.479" stroke-linecap="square"/>' +
      '    <path d="M9.799 31.784l10.459-10.459"/>' +
      "  </g>" +
      "</svg>";
  }

  return L.icon({
    iconUrl: `data:image/svg+xml;utf8,${iconSvg}`,
    iconSize: [35, 60],
    iconAnchor: [17, 60],
  });
}

function createDefaultIcon(appointment: any, selected: boolean) {
  let iconSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="55">';
  // eslint-disable-next-line prettier/prettier
  iconSvg += `<path fill="${
    selected ? "white" : mapAppointmentToColor(appointment)
  }" stroke="black" d="M12.674 43.68C6.054 37.062 1.35 29.918 1.35 23.645c0-6.272 5.052-11.324 11.324-11.324C18.949 12.32 24 17.373 24 23.645c0 6.273-4.705 13.417-11.326 20.035z" vector-effect="non-scaling-stroke"/>`;

  if (appointment.status === "STATUS_REQUESTED") {
    if (appointment.schedulerFlags?.includes("fire-extinguisher")) {
      iconSvg +=
        '<g id="fire-extinguisher" transform="matrix(0.0276378, 0, 0, 0.0290428, 1.85509, 5.0015) translate(110 0)">' +
        '  <path d="m379.07541,965.76501c-15.81403,3 -59.35406,28.65808 -59.35406,78.5l0,289.63306c0,7.18799 3.35498,19.16992 17.73206,19.16992l127.57635,0c14.37805,0 17.73206,-11.98193 17.73206,-19.16992l0,-289.63306c0,-49.84192 -42,-75.5 -58,-78.5l0.073,-20.14093l58.289,0l0,12.461l142.63785,21.56494l0,-97.88495l-142.63785,20.72699l0,13.41907l-58.01001,0l0,-21.06702l-16.77203,0c0,-13.099 -14.22998,-29.90503 -34.46539,-29.90503c-19.70197,0 -37.54102,15.37701 -37.54102,38.59399c0,11.02307 6.33405,20.87708 11.92603,24.23206c-22.15704,8.18701 -51.87097,36.2489 -57.73297,53.34387c-8.14706,16.29407 14.23096,29.95105 25.73297,13.65601c11.50195,-16.29395 24.40997,-36.28992 62.75,-45.875l0.06403,16.875z"/>' +
        '  <path d="m373.34439,917.96814c9.12299,0 16.52039,-7.39807 16.52039,-16.52106c0,-9.12402 -7.3974,-16.52203 -16.52039,-16.52203c-9.125,0 -16.52301,7.39801 -16.52301,16.52203c0,9.12299 7.39801,16.52106 16.52301,16.52106z"/>' +
        "</g> ";
    }

    if (appointment.schedulerFlags?.includes("emergency-lighting")) {
      iconSvg +=
        '<g transform="scale(0.125) translate(50 250)">' +
        '  <path d="M27.986,0C12.531,0,0,12.53,0,27.986c0,5.089,1.43,9.82,3.731,13.973c6.873,12.382,8.693,19.335,9.814,26.064 c0.941,5.641,2.243,6.951,6.504,6.951c1.812,0,4.779,0,7.937,0s6.125,0,7.936,0c4.263,0,5.564-1.312,6.505-6.951 c1.121-6.729,2.942-13.683,9.815-26.064c2.302-4.152,3.73-8.884,3.73-13.973C55.973,12.53,43.442,0,27.986,0z" />' +
        '  <path d="M37.986,77h-20c-0.828,0-1.5,0.671-1.5,1.5v1c0,0.829,0.672,1.5,1.5,1.5h20c0.828,0,1.5-0.671,1.5-1.5v-1 C39.486,77.671,38.814,77,37.986,77z"/>' +
        '  <path d="M37.986,83h-20c-0.828,0-1.5,0.671-1.5,1.5v1c0,0.829,0.672,1.5,1.5,1.5h20c0.828,0,1.5-0.671,1.5-1.5v-1 C39.486,83.671,38.814,83,37.986,83z"/>' +
        '  <path d="M37.986,89h-20c-0.828,0-1.5,0.671-1.5,1.5v1c0,0.829,0.672,1.5,1.5,1.5h20c0.828,0,1.5-0.671,1.5-1.5v-1 C39.486,89.671,38.814,89,37.986,89z"/>' +
        '  <path d="M20.486,95h15c0,3-4,5-7.5,5S20.486,98,20.486,95z"/>' +
        "</g>";
    }

    iconSvg +=
      // eslint-disable-next-line prettier/prettier
      `  <text fill="${
        selected ? "black" : "white"
      }" font-family="Roboto, sans-serif" font-size="14" alignment-baseline="middle" text-anchor="middle">` +
      `    <tspan x="49%" y="55%">${new Date(appointmentTime(appointment)).getMonth() + 1}</tspan>` +
      "  </text>" +
      "</svg>";
  } else {
    iconSvg +=
      `  <g fill="none" stroke="white" stroke-width="2" stroke-miterlimit="10">` +
      '    <path d="M6.319 28.305l3.48 3.479" stroke-linecap="square"/>' +
      '    <path d="M9.799 31.784l10.459-10.459"/>' +
      "  </g>" +
      "</svg>";
  }

  return L.icon({
    iconUrl: `data:image/svg+xml;utf8,${iconSvg}`,
    iconSize: [25, 55],
    iconAnchor: [12, 55],
  });
}

export default function createIconFactory(type: MarkerType, appointment: any, opacity: number, selected: boolean) {
  switch (type) {
    case "star":
      return createStarIcon(appointment, opacity, selected);
    case "square":
      return createSquareIcon(appointment, opacity, selected);
    case "circle":
      return createCircleIcon(appointment, opacity, selected);
    case "hexagon":
      return createHexagonIcon(appointment, opacity, selected);
    default:
      return createDefaultIcon(appointment, selected);
  }
}
