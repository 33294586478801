import * as React from "react";

import initialValues from "./initial-values";

type InitialValues = typeof initialValues;
interface AuthenticationContextProps extends InitialValues {
  loginUser: (credential: string) => Promise<void>;
  logoutUser: () => Promise<void>;
}

const AuthenticationContext = React.createContext<AuthenticationContextProps>({
  ...initialValues,
  loginUser: () => Promise.reject(),
  logoutUser: () => Promise.reject(),
});

export default AuthenticationContext;
