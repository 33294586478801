import { Col, Row } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import RelationCard from "../../Customer/components/RelationTabPaneCard/RelationCard";
import UpdateRelationModal from "../../Customer/components/UpdateRelationModal";
import { DealerBelongingToCard } from "./DealerBelongingToCard";

interface DealerTabPaneProps {
  relationId: string;
}

export function DealerTabPane({ relationId }: DealerTabPaneProps) {
  const navigate = useNavigate();
  const [isUpdateRelationModalOpen, setIsUpdateRelationModalOpen] = useState(false);

  return (
    <>
      {isUpdateRelationModalOpen && <UpdateRelationModal relationId={relationId} onClose={() => setIsUpdateRelationModalOpen(false)} />}

      <Row gutter={24} justify="space-between">
        <Col span={12} style={{ display: "flex", flexDirection: "column", rowGap: 24 }}>
          <RelationCard relationId={relationId} onEditClick={() => setIsUpdateRelationModalOpen(true)} />
        </Col>
        <Col span={12} style={{ display: "flex", flexDirection: "column", rowGap: 24 }}>
          <DealerBelongingToCard onClick={nextValue => navigate(`/relations/customer/${nextValue}`)} relationId={relationId} />
        </Col>
      </Row>
    </>
  );
}
