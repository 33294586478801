import { Checkbox, Form } from "antd";

interface FlagsInputBlockProps {
  prefix: string;
}

export function FlagsInputBlock({ prefix }: FlagsInputBlockProps) {
  return (
    <Form.Item label="Instellingen">
      <Form.Item name={`${prefix}.FLAG_INCLUDES_DISPOSABLES`} valuePropName="checked" style={{ marginBottom: 0 }}>
        <Checkbox>Inclusief disposables</Checkbox>
      </Form.Item>
      <Form.Item name={`${prefix}.FLAG_INVOICE_AFTERWARDS`} valuePropName="checked" style={{ marginBottom: 0 }}>
        <Checkbox>Onderhoudsbeurt los factureren (geen abonnement)</Checkbox>
      </Form.Item>
    </Form.Item>
  );
}
