import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, InputNumber, Space, Table } from "antd";

import ProductTypePicker from "@/components/ProductTypePicker";

export interface AppointmentConsumableGoodInput {
  productTypeId: string | null;
  amount: number | null;
}

interface AppointmentConsumableGoodsTableProps {
  onChange?: (value: AppointmentConsumableGoodInput[]) => void;
  value?: Array<AppointmentConsumableGoodInput>;
}

export function AppointmentConsumableGoodsTable({ onChange, value }: AppointmentConsumableGoodsTableProps) {
  return (
    <Table<AppointmentConsumableGoodInput>
      bordered
      columns={[
        {
          title: "Product",
          dataIndex: "productTypeId",
          width: "70%",
          render: (productTypeId, record, index) => {
            return (
              <ProductTypePicker
                onChange={productTypeId_ => {
                  const nextState = value.with(index, {
                    productTypeId: productTypeId_,
                    amount: record.amount,
                  });

                  onChange?.(nextState);
                }}
                style={{ width: "100%" }}
                value={productTypeId}
              />
            );
          },
        },
        {
          title: "Aantal",
          dataIndex: "amount",
          width: "30%",
          render: (amount, record, index) => {
            return (
              <Space size="small">
                <InputNumber
                  min={0}
                  onChange={amount_ => {
                    const nextState = value.with(index, {
                      amount: amount_,
                      productTypeId: record.productTypeId,
                    });

                    onChange?.(nextState);
                  }}
                  value={amount}
                />
                <Button onClick={() => onChange?.(value.filter((x, index_) => index_ !== index))} icon={<DeleteOutlined />} type="text" />
              </Space>
            );
          },
        },
      ]}
      dataSource={value}
      title={() => (
        <Button icon={<PlusOutlined />} onClick={() => onChange?.([...(value ?? []), { productTypeId: null, amount: 1 }])}>
          Toevoegen
        </Button>
      )}
      rowKey={record => record.productTypeId ?? "_placeholder"}
      pagination={false}
    />
  );
}
