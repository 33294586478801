/* eslint-disable @typescript-eslint/no-var-requires */
import { List } from "antd";
import * as React from "react";

import AppointmentOverviewSpreadsheet from "./components/AppointmentOverviewSpreadsheet";
import AppointmentsForRelationSpreadsheet from "./components/AppointmentsForRelationSpreadsheet";
import AppointmentsInPeriodSpreadsheet from "./components/AppointmentsInPeriodSpreadsheet";
import InventoryRequirementsSpreadsheet from "./components/InventoryRequirementsSpreadsheet";
import InventoryRequirementsSpreadsheetV2 from "./components/InventoryRequirementsSpreadsheetV2";
import InventoryRequirementsTechniciansSpreadsheet from "./components/InventoryRequirementsTechniciansSpreadsheet";
import IssuesSpreadsheet from "./components/IssuesSpreadsheet";
import MissingWorksheetsSpreadsheet from "./components/MissingWorksheetsSpreadsheet";
import ProductsSpreadsheet from "./components/ProductsSpreadsheet";

const reports = [
  IssuesSpreadsheet,
  ProductsSpreadsheet,
  AppointmentsInPeriodSpreadsheet,
  InventoryRequirementsSpreadsheet,
  InventoryRequirementsSpreadsheetV2,
  InventoryRequirementsTechniciansSpreadsheet,
  AppointmentOverviewSpreadsheet,
  AppointmentsForRelationSpreadsheet,
  MissingWorksheetsSpreadsheet,
];

export default function ListAvailableReports() {
  return (
    <List
      dataSource={reports}
      grid={{ column: 2, gutter: 28 }}
      renderItem={(item, index) => <List.Item key={index}>{React.createElement(item)}</List.Item>}
      itemLayout="vertical"
    />
  );
}
