import { AutoComplete, AutoCompleteProps, Form } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { BaseSelectRef } from "rc-select";
import { forwardRef, useMemo, useState } from "react";

import useSuggestions from "./use-suggestions";

interface OptionalDescriptionInputProps {
  productTypePath: NamePath;
  provideSuggestions?: boolean;
  relationId: string;
}

function collectSuggestions(input: Array<{ productTypeId?: string; optionalDescription?: string }>, productTypeId: string) {
  return input
    .filter(i => i.productTypeId === productTypeId)
    .map(i => i.optionalDescription)
    .filter(v => undefined !== v && null !== v) as string[];
}

// eslint-disable-next-line prettier/prettier
function OptionalDescriptionInput(props: OptionalDescriptionInputProps & AutoCompleteProps, ref?: React.Ref<BaseSelectRef>) {
  const { onFocus, productTypePath, provideSuggestions, relationId, ...restProps } = props;

  const form = Form.useFormInstance();
  const productTypeId = Form.useWatch(productTypePath, form);

  const { data, loading } = useSuggestions({ relationId, productTypeId, field: "optionalDescription" });
  const [options, setOptions] = useState<string[]>([]);

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (provideSuggestions) {
      const parts = form.getFieldValue("parts");
      const productTypeId = form.getFieldValue(productTypePath);

      setOptions(collectSuggestions(parts, productTypeId));
    }

    onFocus?.(event);
  };

  const handleOnFilter: AutoCompleteProps["filterOption"] = (input, option) => {
    return (option?.label as string).toLocaleLowerCase().includes(input.toLocaleLowerCase());
  };

  const suggestions = useMemo(() => {
    const onlineSuggestions = data?.productsSuggestions ?? [];
    return [...onlineSuggestions, ...options].map(o => ({ value: o, label: o }));
  }, [data, loading, options]);

  return <AutoComplete ref={ref} onFocus={handleOnFocus} options={suggestions} filterOption={handleOnFilter} {...restProps} />;
}

export default forwardRef(OptionalDescriptionInput);
