export default function prepareIssueComment(value: string | null, username: string) {
  if (value === null) {
    return "";
  }

  const lines = [
    "> " + value, // .
    ">",
    `> -- *${username}*`,
    "",
    "",
  ];

  return lines.join("\n");
}
