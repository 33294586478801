import "leaflet/dist/leaflet.css";

import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";
import { Button, Card, Skeleton } from "antd";
import * as L from "leaflet";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

import LocationPicker from "@/components/LocationPicker";
import downloadAttachment from "@/functions/download-attachment";

import { RelationQuery } from "../../../graphql/RelationQuery";
import defaultIconSvg from "./default-location.svg";
import highlightedIconSvg from "./highlighted-location.svg";

const locationIcon = L.icon({
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  iconUrl: defaultIconSvg,
  iconSize: [25, 55],
  iconAnchor: [12, 55],
});

const selectedLocationIcon = L.icon({
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  iconUrl: highlightedIconSvg,
  iconSize: [25, 55],
  iconAnchor: [12, 55],
});

interface LocationsCardProps {
  locationId: string;
  relationId: string;
  onAdd: () => void;
  onChange: () => void;
}

export default function LocationsCard({ relationId, locationId, onAdd, onChange }: LocationsCardProps) {
  const { data, loading } = useQuery(RelationQuery, { variables: { id: relationId } });
  const locations = data?.relation.locations ?? [];

  const [downloadingExport, setDownloadingExport] = React.useState(false);

  const handleOnDownloadExport = async () => {
    setDownloadingExport(true);

    await downloadAttachment("reports/appointments-relation-overview/" + relationId);

    setDownloadingExport(false);
  };

  const activeLocations = locations.filter(l => !l.deletedAt);
  const mapBounds = (activeLocations.length > 0 ? activeLocations : locations).map(({ address: { coordinates } }) => [
    coordinates.latitude,
    coordinates.longitude,
  ]);

  return (
    <Card
      actions={[
        <Button icon={<FileExcelOutlined />} key="export-xlsx" loading={downloadingExport} onClick={handleOnDownloadExport} type="text">
          Exporteren
        </Button>,
        <Button icon={<PlusOutlined />} key="register-location" onClick={onAdd} type="text">
          Registreren
        </Button>,
      ]}
      loading={loading}
      bodyStyle={{ padding: 0 }}
      title={`Locaties (${activeLocations.length > 0 ? activeLocations.length : "allen verwijderd"})`}
      extra={
        <LocationPicker
          autoSelectDefault
          relationId={relationId}
          onChange={onChange}
          style={{ width: 300 }}
          includeDeleted
          value={locationId}
        />
      }
    >
      {mapBounds.length > 0 ? (
        <ErrorBoundary
          onError={error => {
            captureException(error, {
              tags: {
                url: window.location.toString(),
              },
              contexts: {
                route: {
                  relation_id: relationId,
                  coordinates: mapBounds,
                },
              },
            });
          }}
          fallback={<Skeleton style={{ width: "100%", height: 300 }} />}
        >
          <MapContainer bounds={mapBounds} boundsOptions={{ padding: [20, 20] }} style={{ width: "100%", height: 300 }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {(activeLocations.length > 0 ? activeLocations : locations).map(location => {
              return (
                <Marker
                  key={location.id}
                  eventHandlers={{ click: () => onChange(location.id) }}
                  position={[location.address.coordinates.latitude, location.address.coordinates.longitude]}
                  icon={location.id === locationId ? selectedLocationIcon : locationIcon}
                />
              );
            })}
          </MapContainer>
        </ErrorBoundary>
      ) : (
        <Skeleton style={{ width: "100%", height: 200 }} />
      )}
    </Card>
  );
}
